export enum GtrStatusValue {
  Unknown = 0,
  Voided,
  OpenAvailable,
  Exchanged,
  Suspended,
  UsedFlown,
  Refunded,
  Expired,
  AirportControl,
  Lost,
  Voucher,
  UnusedAvailable,
  Restricted,
  Exception,
  ServiceFee,
  Migrated,
  Inactive,
  BtaUatp,
  NotApplicable,
}
