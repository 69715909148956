import { Gds } from '../../../api/rest/models/Trip/Gds';

export const getDisplayedValue = (value: string | number | boolean | Date | number[] | undefined): string =>
  value?.toString() ?? '-';

export const GDSStatus = {
  [Gds.Other]: 'Other',
  [Gds.Sabre]: 'Sabre',
  [Gds.Apollo]: 'Apollo',
  [Gds.Worldspan]: 'Worldspan',
  [Gds.Amadeus]: 'Amadeus',
  [Gds.Galileo]: 'Galileo',
  [Gds.Abacus]: 'Abacus',
};
