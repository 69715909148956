import React, { useState } from 'react';

import {
  FeatureAccessGroupFormViewModel,
  FeatureAccessGroupViewModel,
} from '../../../models/FeatureAccessGroupsAggregate/FeatureAccessGroup';
import { useNotification, useNotificationReturn } from '../../hooks/notificationHook';
import { getTranslation } from '../../../utils/getTranslation';
import {
  UpdateFeatureAccessGroupContextProps,
  defaultUpdateFeatureAccessGroupContextValue,
  updateFeatureAccessGroupContext,
} from '../../store/FeatureAccessGroups/updateFeatureAccessGroupContext';
import { FeatureAccessGroupService } from '../../../services/FeatureAccessGroupService';
import { mapUpdateFeatureAccessGroup } from '../../../features/AdministrationContainer/FeatureAccessGroups/featureAccessGroupsMapper';
import { FetchError } from './../../../utils/errorHandler';
import { API_REQUEST_CODE } from '../../../utils/APIHandlers';

const WithUpdateFeatureAccessGroup: React.FC = ({ children }) => {
  const [petUserToUpdate, setFeatureAccessGroupToUpdate] = useState<FeatureAccessGroupViewModel | null>(null);
  const [requestUpdateFeatureAccessGroupMessage, setRequestUpdateFeatureAccessGroupMessage] =
    useState<string>(defaultUpdateFeatureAccessGroupContextValue.requestUpdateFeatureAccessGroupMessage);
  const [
    { notificationStatus: requestUpdateFeatureAccessGroupStatus },
    {
      setNotRequestedNotificationStatus: setNotRequestedUpdateFeatureAccessGroup,
      setRequestedNotificationStatus: setRequestedUpdateFeatureAccessGroup,
      setSuccessNotificationStatus: setSuccessUpdateFeatureAccessGroup,
      setFailNotificationStatus: setFailUpdateFeatureAccessGroup,
    },
  ]: useNotificationReturn = useNotification();

  const getUIErrorMessageByStatusCode = (groupName: string, statusCode?: number): string => {
    if (statusCode === API_REQUEST_CODE.CONFLICT) {
      return `${getTranslation('FeatureGroup')} ${groupName} ${getTranslation('AlreadyExists')}`;
    } else {
      return getTranslation('CommonError');
    }
  };

  const updateFeatureAccessGroup = async (
    data: FeatureAccessGroupFormViewModel
  ): Promise<string | undefined | void> => {
    try {
      const featureGroupsService: FeatureAccessGroupService = new FeatureAccessGroupService();
      setRequestedUpdateFeatureAccessGroup();

      await featureGroupsService.update(mapUpdateFeatureAccessGroup(data));

      setSuccessUpdateFeatureAccessGroup();
      setRequestUpdateFeatureAccessGroupMessage(
        `${getTranslation('FeatureAccessGroupsForm_FeatureGroup')} ${data.groupName} ${getTranslation(
          'successEditMessage'
        )}`
      );
    } catch (error: any) {
      setFailUpdateFeatureAccessGroup();

      const statusCode = error instanceof FetchError ? error.response.status : undefined;
      return getUIErrorMessageByStatusCode(data.groupName, statusCode);
    }
  };

  const clearUpdateFeatureAccessGroupState = () => {
    setNotRequestedUpdateFeatureAccessGroup();
    setRequestUpdateFeatureAccessGroupMessage(
      defaultUpdateFeatureAccessGroupContextValue.requestUpdateFeatureAccessGroupMessage
    );
  };

  const clearFeatureAccessGroupToUpdate = () => {
    setFeatureAccessGroupToUpdate(null);
    setNotRequestedUpdateFeatureAccessGroup();
    setRequestUpdateFeatureAccessGroupMessage(
      defaultUpdateFeatureAccessGroupContextValue.requestUpdateFeatureAccessGroupMessage
    );
  };

  const getUpdateFeatureAccessGroupContext = (): UpdateFeatureAccessGroupContextProps => {
    return {
      ...defaultUpdateFeatureAccessGroupContextValue,
      updateFeatureAccessGroup,
      requestUpdateFeatureAccessGroupMessage,
      requestUpdateFeatureAccessGroupStatus,
      clearUpdateFeatureAccessGroupState,

      setFeatureAccessGroupToUpdate,
      clearFeatureAccessGroupToUpdate,
      petUserToUpdate,
    };
  };

  return (
    <updateFeatureAccessGroupContext.Provider value={getUpdateFeatureAccessGroupContext()}>
      {children}
    </updateFeatureAccessGroupContext.Provider>
  );
};

export { WithUpdateFeatureAccessGroup };
