import React, { useState } from 'react';

import {
  defaultExportJobsContextValue,
  exportJobsContext,
  ExportJobsContextProps,
} from '../store/exportJobsContext';
import { API_REQUEST_STATUS, RequestStatus } from '../../utils/APIHandlers';
import { ExportJobsService } from '../../services/ExportJobsService';
import {
  mapExportJobDTOFromRawExportJobDTO,
  sortExportJobsByCreatedUpdatedDatesDESC,
} from './../../features/ExportJobsContainer/exportJobsMapper';
import { ExportJobsListDTO } from '../../api/rest/models/ExportJob/ExportJobCollections';

const WithExportJobs: React.FC = ({ children }) => {
  const [exportJobsData, setExportJobsData] = useState<ExportJobsListDTO>([]);
  const [requestExportJobsStatus, setRequestExportJobsStatus] = useState<RequestStatus>(
    API_REQUEST_STATUS.NOT_REQUESTED
  );

  const getExportJobsList = async () => {
    try {
      const exportJobsService: ExportJobsService = new ExportJobsService();
      setRequestExportJobsStatus(API_REQUEST_STATUS.REQUESTED);
      const response = await exportJobsService.getAll();
      const data: ExportJobsListDTO = response.result.map(mapExportJobDTOFromRawExportJobDTO);
      sortExportJobsByCreatedUpdatedDatesDESC(data);

      setRequestExportJobsStatus(API_REQUEST_STATUS.REQUEST_SUCCESS);
      setExportJobsData(data);
    } catch (error) {
      setRequestExportJobsStatus(API_REQUEST_STATUS.REQUEST_FAILURE);
    }
  };

  const getExportJobsContext = (): ExportJobsContextProps => {
    return {
      ...defaultExportJobsContextValue,
      getExportJobsList,
      exportJobsData,
      requestExportJobsStatus,
    };
  };

  return <exportJobsContext.Provider value={getExportJobsContext()}>{children}</exportJobsContext.Provider>;
};

export { WithExportJobs };
