import React, { SyntheticEvent, useEffect, useContext, useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { Divider, Form, Header, Radio } from 'semantic-ui-react';
import { FieldError } from 'react-hook-form/dist/types';
import { UseFormRegister } from 'react-hook-form/dist/types/form';

import { WithApolloClient } from './../../../WithApolloClient/WithApolloClient';
import InputWithInfo from './../../../PetForm/InputWithInfo';
import SelectWithInfo from './../../../PetForm/SelectWithInfo';
import DateInfo from './../../../PetForm/DateInfo';
import { getTranslation } from './../../../../utils/getTranslation';
import { PetUserFormViewModel } from './../../../../models/PetUsersAggregate/PetUser';
import { EMAIL_REGEX_STRING, getRequiredFieldRegister } from './../../../PetForm/helper';
import { DropdownValueType, FormProps } from './../../../PetForm/types';
import { dataAuthorizationRolesContext } from './../../../../shared/store/dataAuthorizationRolesContext';
import { featureAccessGroupsContext } from '../../../../shared/store/FeatureAccessGroups/featureAccessGroupsContext';
import { getRequestStatuses } from './../../../../utils/APIHandlers';
import {
  mapDataAuthorizationRolesOptions,
  mapFeatureAccessGroupsOptions,
} from './../../../PetForm/SelectWithInfo/helper';
import ResponseErrorMessage from './../../../../shared/controls/ResponseErrorMessage';
import { userContext } from '../../../../shared/store/userContext';
import {
  hasDataAuthorizationManagementAccess,
  hasFeatureAccessManagementAccess,
} from '../../../../pages/Administration/helper';

import './styles.scss';
import './../../../PetForm/commonFormStyles.scss';

interface PetUserFormProps extends FormProps<PetUserFormViewModel, UseFormRegister<PetUserFormViewModel>> {
  client?: ApolloClient<NormalizedCacheObject>;
  errors?: {
    email: FieldError;
    firstName: FieldError;
    lastName: FieldError;
    isFederated: FieldError;
    isEnabled: FieldError;
    featureAccessGroupId: FieldError;
    dataAuthRoleId: FieldError;
  };
}

const PetUserForm: React.FC<PetUserFormProps> = ({ editFormData, errors, control, registerForm }) => {
  const userContextValue = useContext(userContext);
  const { requestDataAuthorizationRolesStatus, dataAuthorizationRolesData, getDataAuthorizationRoles } =
    useContext(dataAuthorizationRolesContext);
  const { requestFeatureAccessGroupsStatus, featureAccessGroupsData, getFeatureAccessGroups } =
    useContext(featureAccessGroupsContext);
  const requiredFieldRegister = getRequiredFieldRegister();
  const isEditForm = Boolean(editFormData.email);
  const requestDataAuthorizationRoles = getRequestStatuses(requestDataAuthorizationRolesStatus);
  const requestFeatureAccessGroup = getRequestStatuses(requestFeatureAccessGroupsStatus);
  const dataAuthRoleIdOption = useMemo(
    () => mapDataAuthorizationRolesOptions(dataAuthorizationRolesData),
    [dataAuthorizationRolesData]
  );
  const featureAccessGroupOption = useMemo(
    () => mapFeatureAccessGroupsOptions(featureAccessGroupsData),
    [featureAccessGroupsData]
  );

  useEffect(() => {
    !requestDataAuthorizationRoles.REQUESTED && void getDataAuthorizationRoles();
    !requestFeatureAccessGroup.REQUESTED && void getFeatureAccessGroups();
  }, []);

  return (
    <>
      <Header as="h1" data-testid="petUserForm-title">
        {isEditForm ? getTranslation('PetUsers_EditUser') : getTranslation('Administration_AddUser')}
      </Header>

      <Form.Group widths="equal">
        <InputWithInfo
          required
          label={getTranslation('FirstName')}
          error={errors?.firstName}
          register={registerForm?.('firstName', requiredFieldRegister)}
          dataTestId="petUserForm-firstName-field"
        />
        <InputWithInfo
          required
          label={getTranslation('LastName')}
          error={errors?.lastName}
          register={registerForm?.('lastName', requiredFieldRegister)}
        />
      </Form.Group>

      <InputWithInfo
        required
        label={getTranslation('PetUserForm_EmailLogin')}
        error={errors?.email}
        disabled={isEditForm}
        register={
          registerForm &&
          registerForm('email', {
            ...requiredFieldRegister,
            pattern: {
              value: EMAIL_REGEX_STRING,
              message: getTranslation('EmailValidation'),
            },
          })
        }
      />

      <Divider />

      <Controller
        render={({ field: { onChange } }) => (
          <>
            <SelectWithInfo
              defaultValue={editFormData.dataAuthRoleId}
              options={dataAuthRoleIdOption}
              required
              isLoaded={requestDataAuthorizationRoles.REQUESTED}
              label={getTranslation('DataAuthorizationRole')}
              error={errors?.dataAuthRoleId ?? requestDataAuthorizationRoles.REQUEST_FAILURE}
              disabled={!hasDataAuthorizationManagementAccess(userContextValue)}
              onChange={(e: SyntheticEvent, { value }: DropdownValueType) => {
                onChange(String(value));
              }}
              dataTestId={'PetUsers-DataAuthRole-messageValidation'}
              noAccessLabel={!hasDataAuthorizationManagementAccess(userContextValue)}
            />

            {requestDataAuthorizationRoles.REQUEST_FAILURE && (
              <ResponseErrorMessage
                onRetryClick={getDataAuthorizationRoles}
                dataTestId="PetUsers-DataAuthRole-messageError"
              />
            )}
          </>
        )}
        name={'dataAuthRoleId'}
        control={control}
        rules={requiredFieldRegister}
      />
      <Controller
        render={({ field: { onChange } }) => (
          <>
            <SelectWithInfo
              defaultValue={editFormData.featureAccessGroupId}
              options={featureAccessGroupOption}
              required
              isLoaded={requestFeatureAccessGroup.REQUESTED}
              label={getTranslation('FeatureGroup')}
              error={errors?.featureAccessGroupId ?? requestFeatureAccessGroup.REQUEST_FAILURE}
              disabled={!hasFeatureAccessManagementAccess(userContextValue)}
              onChange={(e: SyntheticEvent, { value }: DropdownValueType) => {
                onChange(String(value));
              }}
              dataTestId={'PetUsers-FeatureAccessGroup-messageValidation'}
              noAccessLabel={!hasFeatureAccessManagementAccess(userContextValue)}
            />

            {requestFeatureAccessGroup.REQUEST_FAILURE && (
              <ResponseErrorMessage
                onRetryClick={getFeatureAccessGroups}
                dataTestId="PetUsers-FeatureAccessGroup-messageError"
              />
            )}
          </>
        )}
        name={'featureAccessGroupId'}
        control={control}
        rules={requiredFieldRegister}
      />
      <Form.Group inline>
        <Controller
          render={({ field: { onChange } }) => (
            <Radio
              toggle
              label={getTranslation('PetUsers_Enabled')}
              defaultChecked={editFormData.isEnabled}
              onChange={(e, { checked }) => onChange(Boolean(checked))}
            />
          )}
          name="isEnabled"
          control={control}
        />
      </Form.Group>
      <Form.Group inline>
        <Controller
          render={({ field: { onChange } }) => (
            <Radio
              toggle
              label={getTranslation('PetUsers_Federated')}
              defaultChecked={editFormData.isFederated}
              disabled={isEditForm}
              onChange={(e, { checked }) => onChange(Boolean(checked))}
            />
          )}
          name="isFederated"
          control={control}
        />
      </Form.Group>
      {isEditForm && (
        <>
          <Divider />
          <div data-testid="PetUsers-datesInfo-wrapper">
            <DateInfo
              createdDate={editFormData.createdDate}
              createdBy={editFormData.createdBy}
              updatedDate={editFormData.updatedDate}
              updatedBy={editFormData.updatedBy}
            />
          </div>
        </>
      )}
    </>
  );
};

export default WithApolloClient<PetUserFormProps>(PetUserForm);
