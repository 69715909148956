export type SettingsModel = {
  smids: string[];
  gcns: string[];
  fields: string[];
};

export type FiltersModel = { [key: string]: string };

export type CreateReportConfigurationDtoModel = {
  name: string;
  reportId: number;
  settings: SettingsModel;
  filters: FiltersModel;
};

export type UpdateReportConfigurationDtoModel = {
  id: string;
  name: string;
  reportId: number;
  settings: SettingsModel;
  userIds: string[];
  dataAuthRoleIds: string[];
  filters?: FiltersModel;
};

export type ReportConfigurationDtoModel = {
  id: string;
  name: string;
  reportId: number;
  settings: SettingsModel;
  lastModifiedDate: string;
  userIds: string[];
  dataAuthRoleIds: string[];
  filters?: FiltersModel;
};

export type ReportConfigurationModel = {
  id: string;
  name: string;
  reportId: number;
  settings: SettingsModel;
  lastModifiedDate: string;
  userIds: string[];
  dataAuthRoleIds: string[];
  filters?: Map<string, string>;
};

export enum ActionResult {
  Ok,
  AlreadyExists,
  UnknownError,
}

export type TypedActionResult<T> = {
  result: ActionResult;
  data: T;
};

export enum ActionType {
  Get,
  Create,
  Update,
  Delete,
}
