import React, { useState } from 'react';

import { API_REQUEST_STATUS, RequestStatus } from '../../../utils/APIHandlers';
import { FeatureAccessGroupService } from '../../../services/FeatureAccessGroupService';
import { FeatureAccessGroupViewModel } from '../../../models/FeatureAccessGroupsAggregate/FeatureAccessGroup';
import { mapFeatureAccessGroup } from '../../../features/AdministrationContainer/FeatureAccessGroups/featureAccessGroupsMapper';
import {
  FeatureAccessGroupContextProps,
  defaultFeatureAccessGroupContextValue,
  featureAccessGroupContext,
} from '../../store/FeatureAccessGroups/featureAccessGroupContext';

const WithFeatureAccessGroup: React.FC = ({ children }) => {
  const [featureAccessGroupData, setFeatureAccessGroupData] = useState<FeatureAccessGroupViewModel | null>(
    defaultFeatureAccessGroupContextValue.featureAccessGroupData
  );
  const [requestFeatureAccessGroupStatus, setRequestFeatureAccessGroupStatus] = useState<RequestStatus>(
    API_REQUEST_STATUS.NOT_REQUESTED
  );

  const getFeatureAccessGroup = async (id: string) => {
    try {
      const featureAccessGroupService: FeatureAccessGroupService = new FeatureAccessGroupService();
      setRequestFeatureAccessGroupStatus(API_REQUEST_STATUS.REQUESTED);
      const response = await featureAccessGroupService.getById(id);

      setRequestFeatureAccessGroupStatus(API_REQUEST_STATUS.REQUEST_SUCCESS);
      if (response !== null) {
        setFeatureAccessGroupData(mapFeatureAccessGroup(response.result));
      }
    } catch (error) {
      console.error(error);
      setRequestFeatureAccessGroupStatus(API_REQUEST_STATUS.REQUEST_FAILURE);
    }
  };

  const clearFeatureAccessGroupData = () => {
    setRequestFeatureAccessGroupStatus(API_REQUEST_STATUS.NOT_REQUESTED);
    setFeatureAccessGroupData(defaultFeatureAccessGroupContextValue.featureAccessGroupData);
  };

  const getFeatureAccessGroupContext = (): FeatureAccessGroupContextProps => {
    return {
      ...defaultFeatureAccessGroupContextValue,
      getFeatureAccessGroup,
      featureAccessGroupData,
      requestFeatureAccessGroupStatus,
      clearFeatureAccessGroupData,
    };
  };

  return (
    <featureAccessGroupContext.Provider value={getFeatureAccessGroupContext()}>
      {children}
    </featureAccessGroupContext.Provider>
  );
};

export { WithFeatureAccessGroup };
