export const haveSamePairs = <T, U>(
  map1: Map<T, U> | null | undefined,
  map2: Map<T, U> | null | undefined
): boolean => {
  if (map1 === map2) {
    return true;
  }
  if (!map1 || !map2 || map1.size !== map2.size) {
    return false;
  }

  for (const key of Array.from(map1.keys())) {
    if (!map2.has(key) || map2.get(key) !== map1.get(key)) {
      return false;
    }
  }

  return true;
};

export const mergeMaps = <T, U>(map1: Map<T, U> | undefined, map2: Map<T, U>): Map<T, U> => {
  const mergedMap = new Map<T, U>(map1 ?? []);
  Array.from(map2.entries()).forEach(([key, value]) => {
    mergedMap.set(key, value);
  });
  return mergedMap;
};

export const mapToObject = (map: Map<string, string>): { [key: string]: string } => {
  return Array.from(map.entries()).reduce((obj, [key, value]) => {
    obj[key] = value;
    return obj;
  }, {} as { [key: string]: string });
};

export const objectToMap = (obj: { [key: string]: string }): Map<string, string> => {
  return new Map(Object.entries(obj));
};
