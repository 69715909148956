import React, { useContext } from 'react';
import { Message } from 'semantic-ui-react';

import TextButton from '../../../shared/controls/TextButton';
import MessageWithControl from '../../../shared/controls/MessageWithControl';
import { getTranslation } from '../../../utils/getTranslation';
import { API_REQUEST_STATUS } from '../../../utils/APIHandlers';
import { triggerExportJobContext } from '../../../shared/store/triggerExportJobContext';
import { ExportJobDTO } from '../../../api/rest/models/ExportJob/ExportJobDTO';

const TriggerExportJobNotifications: React.FC = () => {
  const {
    notificationTriggerExportJob,
    triggerExportJobData,
    handleCloseTriggerExportJob,
    handleOpenTriggerExportJob,
  } = useContext(triggerExportJobContext);

  return (
    <>
      {notificationTriggerExportJob === API_REQUEST_STATUS.REQUEST_FAILURE && (
        <Message error onDismiss={handleCloseTriggerExportJob} data-testid="messageTriggerExportJob-negative">
          <MessageWithControl message={getTranslation('Error')}>
            <TextButton
              text={getTranslation('RetryTriggerExportJobNow')}
              onClick={() => handleOpenTriggerExportJob(triggerExportJobData as ExportJobDTO)}
              dataTestId="retryTriggerExportJob-control"
            />
          </MessageWithControl>
        </Message>
      )}
      {notificationTriggerExportJob === API_REQUEST_STATUS.REQUEST_SUCCESS && (
        <Message
          success
          content={getTranslation('ExportJobs_Trigger_message_SUCCESS')}
          data-testid="messageTriggerExportJob-positive"
        />
      )}
    </>
  );
};

export default TriggerExportJobNotifications;
