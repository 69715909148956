import React, { useState } from 'react';

import {
  defaultDeleteExportJobContextValue,
  deleteExportJobContext,
  DeleteExportExportJobContextProps,
} from '../store/deleteExportJobContext';
import { API_REQUEST_STATUS } from '../../utils/APIHandlers';
import { ExportJobsService } from '../../services/ExportJobsService';
import { useModal, useModalReturn } from '../hooks/modalHook';
import { useNotification, useNotificationReturn } from '../hooks/notificationHook';

const WithDeleteExportJob: React.FC = ({ children }) => {
  const [
    { isOpen, requestStatus },
    { handleOpen, handleClose, onSuccessAction, onFailAction, setRequestedState },
  ]: useModalReturn = useModal();
  const [
    { notificationStatus },
    { setFailNotificationStatus, setNotRequestedNotificationStatus, setSuccessNotificationStatus },
  ]: useNotificationReturn = useNotification();
  const [deleteExportJobId, setDeleteExportJobId] = useState<string | null>(null);

  const deleteExportJob = async (): Promise<void> => {
    if (requestStatus !== API_REQUEST_STATUS.REQUESTED) {
      try {
        const exportJobsService: ExportJobsService = new ExportJobsService();
        setRequestedState();

        await exportJobsService.del(deleteExportJobId as string);

        onSuccessAction();
        setSuccessNotificationStatus();
      } catch (error) {
        console.error(error);
        onFailAction();
        setFailNotificationStatus();
      }
    }
  };

  const getDeleteExportJobContext = (): DeleteExportExportJobContextProps => {
    return {
      ...defaultDeleteExportJobContextValue,
      isOpenDeleteCondition: isOpen,
      deleteExportJobId,
      deleteExportJob,
      requestDeleteExportJobStatus: requestStatus,
      notificationDeleteExportJob: notificationStatus,
      handleOpenDelete: (id: string): void => {
        setNotRequestedNotificationStatus();
        setDeleteExportJobId(id);
        handleOpen();
      },
      handleCloseDelete: (): void => {
        setDeleteExportJobId(null);
        handleClose();
      },
    };
  };

  return (
    <deleteExportJobContext.Provider value={getDeleteExportJobContext()}>
      {children}
    </deleteExportJobContext.Provider>
  );
};

export { WithDeleteExportJob };
