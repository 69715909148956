import React from 'react';
import { RequestStatus, API_REQUEST_STATUS } from '../../utils/APIHandlers';
import { ExportJobDTO } from '../../api/rest/models/ExportJob/ExportJobDTO';

export interface EnableExportJobContextProps {
  isOpenEnableCondition: boolean;
  enableExportJobStatus: boolean | null;
  enableExportJobData: ExportJobDTO | null;
  enableExportJob: () => Promise<void>;
  requestEnableExportJobStatus: RequestStatus;
  notificationEnableExportJob: RequestStatus;
  handleOpenEnable: (enableExportJobData: ExportJobDTO, nextStatus: boolean) => void;
  handleCloseEnable: () => void;
}

export const defaultEnableExportJobContextValue: EnableExportJobContextProps = {
  isOpenEnableCondition: false,
  enableExportJobStatus: null,
  enableExportJobData: null,
  enableExportJob: async () => new Promise(() => void 0),
  requestEnableExportJobStatus: API_REQUEST_STATUS.NOT_REQUESTED,
  notificationEnableExportJob: API_REQUEST_STATUS.NOT_REQUESTED,
  handleOpenEnable: () => void 0,
  handleCloseEnable: () => void 0,
};

const enableExportJobContext = React.createContext<EnableExportJobContextProps>(
  defaultEnableExportJobContextValue
);

export { enableExportJobContext };
