import React, { useContext } from 'react';
import { Message } from 'semantic-ui-react';

import TextButton from './../../../shared/controls/TextButton';
import MessageWithControl from './../../../shared/controls/MessageWithControl';
import { getTranslation } from './../../../utils/getTranslation';
import { API_REQUEST_STATUS } from './../../../utils/APIHandlers';
import { deleteExportJobContext } from './../../../shared/store/deleteExportJobContext';
import { enableExportJobContext } from './../../../shared/store/enableExportJobContext';
import TriggerExportJobNotifications from './TriggerExportJobNotifications';
import { ExportJobDTO } from '../../../api/rest/models/ExportJob/ExportJobDTO';

const NotificationExportJobs: React.FC = () => {
  const { deleteExportJobId, notificationDeleteExportJob, handleCloseDelete, handleOpenDelete } =
    useContext(deleteExportJobContext);
  const {
    enableExportJobStatus,
    enableExportJobData,
    notificationEnableExportJob,
    handleCloseEnable,
    handleOpenEnable,
  } = useContext(enableExportJobContext);

  return (
    <>
      {notificationDeleteExportJob === API_REQUEST_STATUS.REQUEST_FAILURE && (
        <Message error onDismiss={handleCloseDelete} data-testid="messageDeleteExportJob-negative">
          <MessageWithControl message={getTranslation('Error')}>
            <TextButton
              text={getTranslation('RetryDeleteNow')}
              onClick={() => handleOpenDelete(deleteExportJobId as string)}
              dataTestId="retryDeleteExportJob-control"
            />
          </MessageWithControl>
        </Message>
      )}

      {notificationDeleteExportJob === API_REQUEST_STATUS.REQUEST_SUCCESS && (
        <Message
          success
          content={getTranslation('ExportJobs_Delete_message_SUCCESS')}
          data-testid="messageDeleteExportJob-positive"
        />
      )}

      {notificationEnableExportJob === API_REQUEST_STATUS.REQUEST_FAILURE && (
        <Message error onDismiss={handleCloseEnable} data-testid="messageEnableScheduledReport-negative">
          <MessageWithControl message={getTranslation('Error')}>
            <TextButton
              text={getTranslation(enableExportJobStatus ? 'RetryEnableNow' : 'RetryDisableNow')}
              onClick={() =>
                handleOpenEnable(enableExportJobData as ExportJobDTO, enableExportJobStatus as boolean)
              }
              dataTestId="toggleEnable-retryControl-negative"
            />
          </MessageWithControl>
        </Message>
      )}
      {notificationEnableExportJob === API_REQUEST_STATUS.REQUEST_SUCCESS && (
        <Message
          success
          content={getTranslation(
            enableExportJobStatus ? 'ExportJobs_Enable_message_SUCCESS' : 'ExportJobs_Disable_message_SUCCESS'
          )}
          data-testid="messageEnableScheduledReport-positive"
        />
      )}

      <TriggerExportJobNotifications />
    </>
  );
};

export default NotificationExportJobs;
