/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable sonarjs/no-identical-functions */

import {
  IColumnAggrTarget,
  IColumnTarget,
  IHierarchyLevelTarget,
  IKeyColumnsTarget,
  IKeyHierarchyTarget,
  IMeasureTarget,
  IFilterColumnTarget,
  IFilterKeyColumnsTarget,
  IFilterHierarchyTarget,
  IFilterKeyHierarchyTarget,
  IFilterAggregationTarget,
  IFilterMeasureTarget,
  IFilterColumnAggrTarget,
  IFilterHierarchyAggrTarget,
  IFilterGroupedColumnsTarget,
  IBaseFilterTarget,
  IHierarchyLevelAggrTarget,
  IFilterGeneralTarget,
  IIdentityFilterTarget,
} from 'powerbi-models';

export class PowerBiTargetDetection {
  private static readonly _keyGenerationStrategies = [
    [PowerBiTargetDetection.isIFilterGroupedColumnsTarget, PowerBiTargetDetection.getGroupedColumnsTargetKey],
    [PowerBiTargetDetection.isIFilterHierarchyAggrTarget, PowerBiTargetDetection.getHierarchyAggrTargetKey],
    [PowerBiTargetDetection.isIFilterColumnAggrTarget, PowerBiTargetDetection.getColumnAggrTargetKey],
    [PowerBiTargetDetection.isIFilterMeasureTarget, PowerBiTargetDetection.getMeasureTargetKey],
    [PowerBiTargetDetection.isIFilterAggregationTarget, PowerBiTargetDetection.getAggregationTargetKey],
    [PowerBiTargetDetection.isIFilterKeyHierarchyTarget, PowerBiTargetDetection.getKeyHierarchyTargetKey],
    [PowerBiTargetDetection.isIFilterHierarchyTarget, PowerBiTargetDetection.getHierarchyTargetKey],
    [PowerBiTargetDetection.isIFilterKeyColumnsTarget, PowerBiTargetDetection.getKeyColumnsTargetKey],
    [PowerBiTargetDetection.isIFilterColumnTarget, PowerBiTargetDetection.getColumnTargetKey],
    [
      PowerBiTargetDetection.isIHierarchyLevelAggrTarget,
      PowerBiTargetDetection.getHierarchyLevelAggrTargetKey,
    ],
    [PowerBiTargetDetection.isIBaseFilterTarget, PowerBiTargetDetection.getBaseFilterTargetKey],
    [PowerBiTargetDetection.isIKeyHierarchyTarget, PowerBiTargetDetection.getKeyHierarchyTargetBaseKey],
    [PowerBiTargetDetection.isIHierarchyLevelTarget, PowerBiTargetDetection.getHierarchyLevelTargetKey],
    [PowerBiTargetDetection.isIColumnAggrTarget, PowerBiTargetDetection.getColumnAggrTargetBaseKey],
    [PowerBiTargetDetection.isIKeyColumnsTarget, PowerBiTargetDetection.getKeyColumnsTargetBaseKey],
    [PowerBiTargetDetection.isIColumnTarget, PowerBiTargetDetection.getColumnTargetBaseKey],
    [PowerBiTargetDetection.isIMeasureTarget, PowerBiTargetDetection.getMeasureTargetBaseKey],
    [PowerBiTargetDetection.isNumberArray, PowerBiTargetDetection.getNumberArrayKey],
  ];

  public static getFilterKey(target: IFilterGeneralTarget): string {
    for (const [predicate, keyGenerator] of PowerBiTargetDetection._keyGenerationStrategies) {
      if (predicate(target)) {
        return keyGenerator(target).toString();
      }
    }
    return JSON.stringify(target);
  }

  private static getGroupedColumnsTargetKey(target: IFilterGroupedColumnsTarget): string {
    return `${target.table}_${target.column}_${target.groupedColumns
      .map(PowerBiTargetDetection.getFilterKey)
      .join(',')}`;
  }

  private static getHierarchyAggrTargetKey(target: IFilterHierarchyAggrTarget): string {
    return `${target.table}_${target.hierarchy}_${target.hierarchyLevel}_${target.aggregationFunction}_${
      target.percentOfGrandTotal?.toString() ?? ''
    }`;
  }

  private static getColumnAggrTargetKey(target: IFilterColumnAggrTarget): string {
    return `${target.table}_${target.column}_${target.aggregationFunction}_${
      target.percentOfGrandTotal?.toString() ?? ''
    }`;
  }

  private static getMeasureTargetKey(target: IFilterMeasureTarget): string {
    return `${target.table}_${target.measure}_${target.percentOfGrandTotal?.toString() ?? ''}`;
  }

  private static getAggregationTargetKey(target: IFilterAggregationTarget): string {
    return `${target.table}_${target.aggregationFunction}_${target.percentOfGrandTotal?.toString() ?? ''}`;
  }

  private static getKeyHierarchyTargetKey(target: IFilterKeyHierarchyTarget): string {
    return `${target.table}_${target.hierarchy}_${target.hierarchyLevel}_${target.keys.join(',')}`;
  }

  private static getHierarchyTargetKey(target: IFilterHierarchyTarget): string {
    return `${target.table}_${target.hierarchy}_${target.hierarchyLevel}`;
  }

  private static getKeyColumnsTargetKey(target: IFilterKeyColumnsTarget): string {
    return `${target.table}_${target.column}_${target.keys.join(',')}`;
  }

  private static getColumnTargetKey(target: IFilterColumnTarget): string {
    return `${target.table}_${target.column}`;
  }

  private static getHierarchyLevelAggrTargetKey(target: IHierarchyLevelAggrTarget): string {
    return `${target.table}_${target.hierarchy}_${target.hierarchyLevel}_${target.aggregationFunction}_${
      target.percentOfGrandTotal?.toString() ?? ''
    }`;
  }

  private static getBaseFilterTargetKey(target: IBaseFilterTarget): string {
    return `${target.table}`;
  }

  private static getKeyHierarchyTargetBaseKey(target: IKeyHierarchyTarget): string {
    return `${target.table}_${target.hierarchy}_${target.hierarchyLevel}_${target.keys.join(',')}`;
  }

  private static getHierarchyLevelTargetKey(target: IHierarchyLevelTarget): string {
    return `${target.table}_${target.hierarchy}_${target.hierarchyLevel}`;
  }

  private static getColumnAggrTargetBaseKey(target: IColumnAggrTarget): string {
    return `${target.table}_${target.column}_${target.aggregationFunction}_${
      target.percentOfGrandTotal?.toString() ?? ''
    }`;
  }

  private static getKeyColumnsTargetBaseKey(target: IKeyColumnsTarget): string {
    return `${target.table}_${target.column}_${target.keys.join(',')}`;
  }

  private static getColumnTargetBaseKey(target: IColumnTarget): string {
    return `${target.table}_${target.column}`;
  }

  private static getMeasureTargetBaseKey(target: IMeasureTarget): string {
    return `${target.table}_${target.measure}_${target.percentOfGrandTotal?.toString() ?? ''}`;
  }

  private static getNumberArrayKey(target: IIdentityFilterTarget): string {
    return target.join(',');
  }

  private static isNumberArray(value: any): boolean {
    return Array.isArray(value) && value.every((item) => typeof item === 'number');
  }

  private static isIFilterGroupedColumnsTarget(target: any): boolean {
    return (
      target &&
      Array.isArray(target.groupedColumns) &&
      target.groupedColumns.every(PowerBiTargetDetection.isIFilterTarget) &&
      PowerBiTargetDetection.isIFilterColumnTarget(target)
    );
  }

  private static isIFilterHierarchyAggrTarget(target: any): boolean {
    return (
      target &&
      PowerBiTargetDetection.isIFilterHierarchyTarget(target) &&
      PowerBiTargetDetection.isIFilterAggregationTarget(target)
    );
  }

  private static isIFilterColumnAggrTarget(target: any): boolean {
    return (
      target &&
      PowerBiTargetDetection.isIFilterColumnTarget(target) &&
      PowerBiTargetDetection.isIFilterAggregationTarget(target)
    );
  }

  private static isIFilterMeasureTarget(target: any): boolean {
    return (
      target &&
      PowerBiTargetDetection.isIMeasureTarget(target) &&
      PowerBiTargetDetection.isIBaseFilterTarget(target)
    );
  }

  private static isIFilterAggregationTarget(target: any): boolean {
    return (
      target &&
      PowerBiTargetDetection.isIBaseFilterTarget(target) &&
      PowerBiTargetDetection.isIAggregationTarget(target)
    );
  }

  private static isIFilterKeyHierarchyTarget(target: any): boolean {
    return (
      target &&
      PowerBiTargetDetection.isIFilterHierarchyTarget(target) &&
      PowerBiTargetDetection.isIKeyHierarchyTarget(target)
    );
  }

  private static isIFilterHierarchyTarget(target: any): boolean {
    return (
      target &&
      PowerBiTargetDetection.isIHierarchyLevelTarget(target) &&
      PowerBiTargetDetection.isIBaseFilterTarget(target)
    );
  }

  private static isIFilterKeyColumnsTarget(target: any): boolean {
    return (
      target &&
      PowerBiTargetDetection.isIFilterColumnTarget(target) &&
      PowerBiTargetDetection.isIKeyColumnsTarget(target)
    );
  }

  private static isIFilterColumnTarget(target: any): boolean {
    return (
      target &&
      PowerBiTargetDetection.isIColumnTarget(target) &&
      PowerBiTargetDetection.isIBaseFilterTarget(target)
    );
  }

  private static isIHierarchyLevelAggrTarget(target: any): boolean {
    return (
      target &&
      PowerBiTargetDetection.isIHierarchyLevelTarget(target) &&
      PowerBiTargetDetection.isIAggregationTarget(target)
    );
  }

  private static isIBaseFilterTarget(target: any): boolean {
    return target && PowerBiTargetDetection.isIBaseTarget(target);
  }

  private static isIKeyHierarchyTarget(target: any): boolean {
    return (
      target &&
      Array.isArray(target.keys) &&
      target.keys.every((x: any) => typeof x === 'string') &&
      PowerBiTargetDetection.isIHierarchyLevelTarget(target)
    );
  }

  private static isIHierarchyLevelTarget(target: any): boolean {
    return (
      target &&
      typeof target.hierarchy === 'string' &&
      typeof target.hierarchyLevel === 'string' &&
      PowerBiTargetDetection.isIBaseTarget(target)
    );
  }

  private static isIColumnAggrTarget(target: any): boolean {
    return (
      target &&
      PowerBiTargetDetection.isIColumnTarget(target) &&
      PowerBiTargetDetection.isIAggregationTarget(target)
    );
  }

  private static isIAggregationTarget(target: any): boolean {
    return (
      target &&
      typeof target.aggregationFunction === 'string' &&
      PowerBiTargetDetection.isIPercentOfGrandTotalTarget(target)
    );
  }

  private static isIKeyColumnsTarget(target: any): boolean {
    return (
      target &&
      Array.isArray(target.keys) &&
      target.keys.every((x: any) => typeof x === 'string') &&
      PowerBiTargetDetection.isIColumnTarget(target)
    );
  }

  private static isIColumnTarget(target: any): boolean {
    return target && typeof target.column === 'string' && PowerBiTargetDetection.isIBaseTarget(target);
  }

  private static isIMeasureTarget(target: any): boolean {
    return (
      target &&
      typeof target.measure === 'string' &&
      PowerBiTargetDetection.isIBaseTarget(target) &&
      PowerBiTargetDetection.isIPercentOfGrandTotalTarget(target)
    );
  }

  private static isIBaseTarget(target: any): boolean {
    return target && typeof target.table === 'string';
  }

  private static isIPercentOfGrandTotalTarget(target: any): boolean {
    return (
      target && (target.percentOfGrandTotal === undefined || typeof target.percentOfGrandTotal === 'boolean')
    );
  }

  private static isINotSupportedFilterTarget(target: any): boolean {
    return this.isIBaseFilterTarget(target);
  }

  private static isIFilterTarget(target: any): boolean {
    return (
      PowerBiTargetDetection.isIFilterColumnTarget(target) ||
      PowerBiTargetDetection.isIFilterHierarchyTarget(target) ||
      PowerBiTargetDetection.isIFilterMeasureTarget(target) ||
      PowerBiTargetDetection.isINotSupportedFilterTarget(target) ||
      PowerBiTargetDetection.isIFilterColumnAggrTarget(target) ||
      PowerBiTargetDetection.isIFilterHierarchyAggrTarget(target)
    );
  }
}
