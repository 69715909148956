import React, { useState, useEffect } from 'react';
import { Location as HistoryLocation } from 'history';

import { useModal, useModalReturn } from '../hooks/modalHook';
import { useNotification, useNotificationReturn } from '../hooks/notificationHook';
import {
  defaultCreateExportJobContextValue,
  CreateExportJobContextProps,
  createExportJobContext,
} from '../store/createExportJobContext';
import { API_REQUEST_STATUS } from '../../utils/APIHandlers';
import { ExportJobsService } from '../../services/ExportJobsService';
import {
  mapToCreateExportJobDTOFromExportJobDTO,
  mapToEditExportJobDTOFromExportJobDTO,
} from '../../features/ExportJobsContainer/exportJobsMapper';
import { ExportJobDTO } from '../../api/rest/models/ExportJob/ExportJobDTO';

const WithCreateJob: React.FC = ({ children }) => {
  const [isEdit, setEdit] = useState<boolean>(false);
  const [
    { isOpen, requestStatus },
    { handleOpen, handleClose, onSuccessAction, setFailState, setRequestedState },
  ]: useModalReturn = useModal();
  const [
    { notificationStatus },
    { setFailNotificationStatus, setNotRequestedNotificationStatus, setSuccessNotificationStatus },
  ]: useNotificationReturn = useNotification();
  const [exportJobData, setJobData] = useState<ExportJobDTO | null>(null);
  const [isOpenLeaveCondition, setIsOpenLeaveCondition] = useState<boolean>(false);
  const [leaveDirection, setLeaveDirection] = useState<string | null>(null);

  useEffect(() => {
    !isOpen && isEdit && setEdit(false);
  }, [isOpen]);

  const createExportJob = async (): Promise<void> => {
    if (requestStatus !== API_REQUEST_STATUS.REQUESTED && exportJobData !== null) {
      try {
        const exportJobsService: ExportJobsService = new ExportJobsService();
        setRequestedState();

        await exportJobsService.create(mapToCreateExportJobDTOFromExportJobDTO(exportJobData));

        onSuccessAction();
        setSuccessNotificationStatus();
      } catch (error) {
        setFailState();
        setFailNotificationStatus();
      }
    }
  };

  const editJob = async (): Promise<void> => {
    if (requestStatus !== API_REQUEST_STATUS.REQUESTED && exportJobData !== null) {
      try {
        const exportJobsService: ExportJobsService = new ExportJobsService();
        setRequestedState();

        await exportJobsService.update(
          mapToEditExportJobDTOFromExportJobDTO({
            ...exportJobData,
          })
        );

        onSuccessAction();
        setSuccessNotificationStatus();
      } catch (error) {
        setFailState();
        setFailNotificationStatus();
      }
    }
  };

  const getJobContext = (): CreateExportJobContextProps => {
    return {
      ...defaultCreateExportJobContextValue,
      isOpenCreateCondition: isOpen,
      exportJobData,
      isEdit,
      createExportJob,
      editJob,
      requestCreateJobStatus: requestStatus,
      notificationCreateJob: notificationStatus,
      handleOpenCreateJob: (input: ExportJobDTO, isEditExportJob?: boolean): void => {
        isEditExportJob && setEdit(true);
        setNotRequestedNotificationStatus();

        setJobData(input);
        handleOpen();
      },
      handleCloseCreateJob: (): void => {
        setNotRequestedNotificationStatus();
        setJobData(null);
        handleClose();
      },
      isOpenLeaveCondition,
      leaveDirection,
      handleOpenLeaveCreateJob: (direction: HistoryLocation): void => {
        setIsOpenLeaveCondition(true);
        setLeaveDirection(direction?.pathname);
      },
      handleCloseLeaveCreateJob: (): void => {
        setIsOpenLeaveCondition(false);
        setLeaveDirection(null);
      },
    };
  };

  return (
    <createExportJobContext.Provider value={getJobContext()}>{children}</createExportJobContext.Provider>
  );
};

export { WithCreateJob };
