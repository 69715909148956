import { RestApiResponse } from '../api/rest/models/RestApiResponse';
import { APIFetch } from '../utils/APIHandlers';
import { PetApi } from '../constants/apiParams';
import { GtrTripCanonicalModelDTO } from '../api/rest/models/Trip/GtrTripCanonicalModelDTO';
import { TripsTicketsSearchRequestDTO } from '../api/rest/models/Trip/TripsTicketsSearchRequestDTO';
import { RecordsAndTickets } from '../features/SystemOndemand/types';

export class TripsService {
  private readonly endpoint: string;

  constructor() {
    this.endpoint = '/v1/tickets/pet/trips';
  }

  public async searchTripsTickets(
    payload: TripsTicketsSearchRequestDTO
  ): Promise<RestApiResponse<GtrTripCanonicalModelDTO[]>> {
    return await APIFetch<RestApiResponse<GtrTripCanonicalModelDTO[]>>(
      'post',
      PetApi,
      `${this.endpoint}/search`,
      {
        body: payload,
      }
    );
  }

  public async getById(id: string): Promise<RestApiResponse<GtrTripCanonicalModelDTO>> {
    return await APIFetch<RestApiResponse<GtrTripCanonicalModelDTO>>(
      'get',
      PetApi,
      `${this.endpoint}/${id}`,
      {}
    );
  }

  public async schedule(recordsAndTickets: RecordsAndTickets): Promise<RestApiResponse> {
    return await APIFetch<RestApiResponse>('post', PetApi, `${this.endpoint}/schedule`, {
      body: recordsAndTickets,
    });
  }
}
