import React, { useState } from 'react';

import { API_REQUEST_STATUS, RequestStatus } from '../../../utils/APIHandlers';
import { countItemsPerPage } from '../../../pages/Administration/constant';
import {
  GetPaginatedFeatureAccessGroupsProps,
  PaginatedFeatureAccessGroupsContextProps,
  defaultPaginatedFeatureAccessGroupsContextValue as defaultContextValue,
  paginatedFeatureAccessGroupsContext,
} from '../../store/FeatureAccessGroups/paginatedFeatureAccessGroupsContext';
import { FeatureAccessGroupViewModel } from '../../../models/FeatureAccessGroupsAggregate/FeatureAccessGroup';
import { FeatureAccessGroupService } from '../../../services/FeatureAccessGroupService';
import { mapFeatureAccessGroups } from '../../../features/AdministrationContainer/FeatureAccessGroups/featureAccessGroupsMapper';

const WithPaginatedFeatureAccessGroups: React.FC = ({ children }) => {
  const [featureAccessGroups, setFeatureAccessGroups] = useState<FeatureAccessGroupViewModel[]>(
    defaultContextValue.featureAccessGroups
  );
  const [totalFeatureAccessGroups, setTotalFeatureAccessGroups] = useState<number>(
    defaultContextValue.totalFeatureAccessGroups
  );
  const [activePageFeatureAccessGroups, setActivePageFeatureAccessGroups] = useState<number>(
    defaultContextValue.activePageFeatureAccessGroups
  );
  const [requestFeatureAccessGroupsStatus, setRequestFeatureAccessGroupsStatus] = useState<RequestStatus>(
    defaultContextValue.requestFeatureAccessGroupsStatus
  );

  const getFeatureAccessGroups = async (
    payload: GetPaginatedFeatureAccessGroupsProps = { page: activePageFeatureAccessGroups }
  ) => {
    try {
      const featureAccessGroupService: FeatureAccessGroupService = new FeatureAccessGroupService();
      setRequestFeatureAccessGroupsStatus(API_REQUEST_STATUS.REQUESTED);

      const response = await featureAccessGroupService.getAll({
        limit: countItemsPerPage,
        offset: (payload.page - 1) * countItemsPerPage,
      });

      setActivePageFeatureAccessGroups(payload.page);
      setRequestFeatureAccessGroupsStatus(API_REQUEST_STATUS.REQUEST_SUCCESS);
      setFeatureAccessGroups(mapFeatureAccessGroups(response.result.data));
      setTotalFeatureAccessGroups(response.result.total);
    } catch (error) {
      console.error(error);
      setRequestFeatureAccessGroupsStatus(API_REQUEST_STATUS.REQUEST_FAILURE);
    }
  };
  const getFeatureAccessGroupsContext = (): PaginatedFeatureAccessGroupsContextProps => {
    return {
      ...defaultContextValue,
      getFeatureAccessGroups,
      featureAccessGroups,
      totalFeatureAccessGroups,
      activePageFeatureAccessGroups,
      requestFeatureAccessGroupsStatus,
      setFeatureAccessGroupsActivePage: setActivePageFeatureAccessGroups,
    };
  };

  return (
    <paginatedFeatureAccessGroupsContext.Provider value={getFeatureAccessGroupsContext()}>
      {children}
    </paginatedFeatureAccessGroupsContext.Provider>
  );
};

export { WithPaginatedFeatureAccessGroups };
