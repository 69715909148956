import React from 'react';
import { Grid, Label, List, Header } from 'semantic-ui-react';
import ExpressionDescriptor from 'cronstrue';

import { DeliveryMethodValue, DeliveryMethod } from '../../../models/ExportJobsAggregate/ExportJobDelivery';
import { FormatValue } from '../../../models/ExportJobsAggregate/ExportJobFormat';
import TableLabel from '../../TableLabel';
import { getTranslation } from '../../../utils/getTranslation';
import { DEFAULT_CRON_VALUE } from './../../../features/ExportJobsContainer/ExportJobHistory/constants';
import { getUTCStringFromDate } from '../../../utils/formatDate';
import { getPeriodLabel } from './helper';
import { ExportJobDTO } from '../../../api/rest/models/ExportJob/ExportJobDTO';

import './styles.scss';

interface JobHistoryProps {
  data: ExportJobDTO;
}

const ShortExportJobDetails: React.FC<JobHistoryProps> = (props) => {
  const { data } = props;

  return (
    <Grid className="container-exportJobShortDetails">
      <Grid.Row className="arbitraryWidth" verticalAlign="top">
        <Grid.Column>
          <Header as="h2" className="container-jobHistoryTitle" data-testid="jobHistoryJobName">
            {data.jobName}
          </Header>
        </Grid.Column>
        <Grid.Column data-testid="jobHistoryEnable" className="container-jobHistoryTitleLabel">
          {data.enabled ? (
            <TableLabel text={getTranslation('Enabled')} active={true} />
          ) : (
            <TableLabel text={getTranslation('Disabled')} active={false} />
          )}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <List divided relaxed>
            <List.Item>
              <Grid>
                <Grid.Row>
                  <Grid.Column width={8}>
                    <List.Header>{getTranslation('JobHistory_ExportJob_Report')}</List.Header>
                    <List.Description data-testid="jobHistoryReport">
                      {data.reportDetails?.report?.name || getTranslation('Empty_Word')}
                    </List.Description>
                  </Grid.Column>
                  <Grid.Column width={8}>
                    <List.Header>{getTranslation('JobHistory_ExportJob_Format')}</List.Header>
                    <List.Description data-testid="jobHistoryFormat">
                      {
                        <Label>
                          {typeof data.format === 'number'
                            ? FormatValue[data.format]
                            : getTranslation('Empty_Word')}
                        </Label>
                      }
                    </List.Description>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </List.Item>

            <List.Item>
              <List.Header>{getTranslation('JobHistory_ExportJob_PeriodOfData')}</List.Header>
              <List.Description data-testid="jobHistoryPeriodOfData">{getPeriodLabel(data)}</List.Description>
            </List.Item>

            <List.Item>
              <List.Header>{getTranslation('JobHistory_ExportJob_Schedule')}</List.Header>
              <List.Description data-testid="jobHistorySchedule">
                {ExpressionDescriptor.toString(data.cronExpression || DEFAULT_CRON_VALUE)}
              </List.Description>
            </List.Item>

            <List.Item>
              <List.Header>{getTranslation('JobHistory_ExportJob_ActiveDates')}</List.Header>
              <List.Description data-testid="jobHistoryActiveDates">
                {`${getUTCStringFromDate(data.activeStartDate)} - ${getUTCStringFromDate(
                  data.activeEndDate
                )}`}
              </List.Description>
            </List.Item>

            <List.Item>
              <List.Header>{getTranslation('DataAuthorizationRole')}</List.Header>
              <List.Description data-testid="jobHistoryDataAuthRole">
                {data.dataAuthorizationRole?.name || getTranslation('Empty_Word')}
              </List.Description>
            </List.Item>

            <List.Item>
              <Grid>
                <Grid.Row>
                  <Grid.Column width={8}>
                    <List.Header>{getTranslation('JobHistory_ExportJob_DeliveryMethod')}</List.Header>
                    <List.Description data-testid="jobHistoryDeliveryMethod">
                      {typeof data.deliveryMethod === 'number'
                        ? DeliveryMethodValue[data.deliveryMethod]
                        : ''}
                    </List.Description>
                  </Grid.Column>
                  <Grid.Column width={8}>
                    {data.deliveryMethod === DeliveryMethod.Ftp && (
                      <List.Item>
                        <List.Header>{getTranslation('JobHistory_ExportJob_Provider')}</List.Header>
                        <List.Description data-testid="jobHistoryProvider">{data.provider}</List.Description>
                      </List.Item>
                    )}

                    {data.deliveryMethod === DeliveryMethod.Email && (
                      <List.Item>
                        <List.Header>{getTranslation('JobHistory_ExportJob_Emails')}</List.Header>
                        <List.Description data-testid="jobHistoryEmail">
                          {data.userEmails.map((item) => (
                            <Label key={item}>{item}</Label>
                          ))}
                          {data.userEmails.length === 0 && <p>{getTranslation('Empty_Word')}</p>}
                        </List.Description>
                      </List.Item>
                    )}
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </List.Item>

            {data.createdDate && (
              <List.Item>
                <List.Header>{getTranslation('JobHistory_ExportJob_Created')}</List.Header>
                <List.Description data-testid="jobHistoryCreated">
                  {`${getTranslation('BySBD')} ${data.createdBy || '-'},`}
                  <br />
                  {`${getTranslation('AtTime')} ${getUTCStringFromDate(data.createdDate)}`}
                </List.Description>
              </List.Item>
            )}

            {data.updatedDate && (
              <List.Item>
                <List.Header>{getTranslation('JobHistory_ExportJob_Updated')}</List.Header>
                <List.Description data-testid="jobHistoryUpdated">
                  {`${getTranslation('BySBD')} ${data.updatedBy || '-'},`}
                  <br />
                  {`${getTranslation('AtTime')} ${getUTCStringFromDate(data.updatedDate)}`}
                </List.Description>
              </List.Item>
            )}
          </List>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default ShortExportJobDetails;
