import React, { useState } from 'react';

import {
  defaultEnableExportJobContextValue,
  enableExportJobContext,
  EnableExportJobContextProps,
} from '../store/enableExportJobContext';
import { API_REQUEST_STATUS } from '../../utils/APIHandlers';
import { ExportJobsService } from '../../services/ExportJobsService';
import { useModal, useModalReturn } from '../hooks/modalHook';
import { useNotification, useNotificationReturn } from '../hooks/notificationHook';
import { mapToEditExportJobDTOFromExportJobDTO } from '../../features/ExportJobsContainer/exportJobsMapper';
import { ExportJobDTO } from '../../api/rest/models/ExportJob/ExportJobDTO';

const WithEnableExportJob: React.FC = ({ children }) => {
  const [
    { isOpen, requestStatus },
    { handleOpen, handleClose, onSuccessAction, onFailAction, setRequestedState },
  ]: useModalReturn = useModal();
  const [
    { notificationStatus },
    { setFailNotificationStatus, setNotRequestedNotificationStatus, setSuccessNotificationStatus },
  ]: useNotificationReturn = useNotification();
  const [enableExportJobStatus, setEnableExportJobStatus] = useState<boolean | null>(null);
  const [enableExportJobData, setEnableExportJob] = useState<ExportJobDTO | null>(null);

  const enableExportJob = async (): Promise<void> => {
    if (requestStatus !== API_REQUEST_STATUS.REQUESTED && enableExportJobData !== null) {
      try {
        const exportJobsService: ExportJobsService = new ExportJobsService();
        setRequestedState();

        await exportJobsService.update(
          mapToEditExportJobDTOFromExportJobDTO({
            ...enableExportJobData,
            enabled: enableExportJobStatus as boolean,
          })
        );

        onSuccessAction();
        setSuccessNotificationStatus();
      } catch (error) {
        console.error(error);
        onFailAction();
        setFailNotificationStatus();
      }
    }
  };

  const getEnableExportJobContext = (): EnableExportJobContextProps => {
    return {
      ...defaultEnableExportJobContextValue,
      isOpenEnableCondition: isOpen,
      enableExportJobStatus,
      enableExportJobData: enableExportJobData,
      enableExportJob,
      requestEnableExportJobStatus: requestStatus,
      notificationEnableExportJob: notificationStatus,
      handleOpenEnable: (data: ExportJobDTO, nextStatus: boolean): void => {
        setNotRequestedNotificationStatus();
        setEnableExportJobStatus(nextStatus);
        setEnableExportJob(data);
        handleOpen();
      },
      handleCloseEnable: (): void => {
        setNotRequestedNotificationStatus();
        setEnableExportJobStatus(null);
        setEnableExportJob(null);
        handleClose();
      },
    };
  };

  return (
    <enableExportJobContext.Provider value={getEnableExportJobContext()}>
      {children}
    </enableExportJobContext.Provider>
  );
};

export { WithEnableExportJob };
