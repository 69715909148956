import React, { useState } from 'react';

import { FeatureAccessGroupFormViewModel } from '../../../models/FeatureAccessGroupsAggregate/FeatureAccessGroup';
import { useNotification, useNotificationReturn } from '../../hooks/notificationHook';
import { getTranslation } from '../../../utils/getTranslation';
import {
  CreateFeatureAccessGroupContextProps,
  defaultCreateFeatureAccessGroupContextValue,
  createFeatureAccessGroupContext,
} from '../../store/FeatureAccessGroups/createFeatureAccessGroupContext';
import { FeatureAccessGroupService } from '../../../services/FeatureAccessGroupService';
import { mapCreateFeatureAccessGroup } from '../../../features/AdministrationContainer/FeatureAccessGroups/featureAccessGroupsMapper';
import { API_REQUEST_CODE } from '../../../utils/APIHandlers';
import { FetchError } from '../../../utils/errorHandler';

const WithCreateFeatureAccessGroup: React.FC = ({ children }) => {
  const [requestCreateFeatureAccessGroupMessage, setRequestCreateFeatureAccessGroupMessage] =
    useState<string>(defaultCreateFeatureAccessGroupContextValue.requestCreateFeatureAccessGroupMessage);
  const [
    { notificationStatus: requestCreateFeatureAccessGroupStatus },
    {
      setNotRequestedNotificationStatus: setNotRequestedCreateFeatureAccessGroup,
      setRequestedNotificationStatus: setRequestedCreateFeatureAccessGroup,
      setSuccessNotificationStatus: setSuccessCreateFeatureAccessGroup,
      setFailNotificationStatus: setFailCreateFeatureAccessGroup,
    },
  ]: useNotificationReturn = useNotification();

  const getUIErrorMessageByStatusCode = (groupName: string, statusCode?: number): string => {
    if (statusCode === API_REQUEST_CODE.CONFLICT) {
      return `${getTranslation('FeatureGroup')} ${groupName} ${getTranslation('AlreadyExists')}`;
    } else {
      return getTranslation('CommonError');
    }
  };

  const createFeatureAccessGroup = async (
    data: FeatureAccessGroupFormViewModel
  ): Promise<string | undefined | void> => {
    try {
      const featureGroupsService: FeatureAccessGroupService = new FeatureAccessGroupService();
      setRequestedCreateFeatureAccessGroup();

      await featureGroupsService.insert(mapCreateFeatureAccessGroup(data));

      setSuccessCreateFeatureAccessGroup();
      setRequestCreateFeatureAccessGroupMessage(
        `${getTranslation('FeatureAccessGroupsForm_FeatureGroup')} ${data.groupName} ${getTranslation(
          'successMessage'
        )}`
      );
    } catch (error: any) {
      setFailCreateFeatureAccessGroup();

      const statusCode = error instanceof FetchError ? error.response.status : undefined;
      return getUIErrorMessageByStatusCode(data.groupName, statusCode);
    }
  };

  const clearCreateFeatureAccessGroupState = () => {
    setNotRequestedCreateFeatureAccessGroup();
    setRequestCreateFeatureAccessGroupMessage(
      defaultCreateFeatureAccessGroupContextValue.requestCreateFeatureAccessGroupMessage
    );
  };

  const getCreateFeatureAccessGroupContext = (): CreateFeatureAccessGroupContextProps => {
    return {
      ...defaultCreateFeatureAccessGroupContextValue,
      createFeatureAccessGroup,
      requestCreateFeatureAccessGroupMessage,
      requestCreateFeatureAccessGroupStatus,
      clearCreateFeatureAccessGroupState,
    };
  };

  return (
    <createFeatureAccessGroupContext.Provider value={getCreateFeatureAccessGroupContext()}>
      {children}
    </createFeatureAccessGroupContext.Provider>
  );
};

export { WithCreateFeatureAccessGroup };
