import React, { useState } from 'react';

import { useModal, useModalReturn } from '../hooks/modalHook';
import {
  defaultExportJobContextValue,
  exportJobContext,
  ExportJobContextProps,
} from '../store/exportJobContext';
import { API_REQUEST_STATUS } from '../../utils/APIHandlers';
import { ExportJobsService } from '../../services/ExportJobsService';
import { mapExportJobDTOFromRawExportJobDTO } from '../../features/ExportJobsContainer/exportJobsMapper';
import { ExportJobDTO } from '../../api/rest/models/ExportJob/ExportJobDTO';

const WithExportJob: React.FC = ({ children }) => {
  const [{ requestStatus }, { setFailState, setRequestedState, setSuccessState }]: useModalReturn =
    useModal();
  const [exportJobData, setJobData] = useState<ExportJobDTO | null>(null);

  const getExportJob = async (id: string): Promise<void> => {
    if (requestStatus !== API_REQUEST_STATUS.REQUESTED) {
      try {
        const exportJobsService: ExportJobsService = new ExportJobsService();
        setRequestedState();

        const response = await exportJobsService.getById(id);
        const data = mapExportJobDTOFromRawExportJobDTO(response.result);

        setJobData(data);
        setSuccessState();
      } catch (error) {
        console.error(error);
        setFailState();
      }
    }
  };

  const getJobContext = (): ExportJobContextProps => {
    return {
      ...defaultExportJobContextValue,
      exportJobData,
      getExportJob: getExportJob,
      clearExportJobData: () => setJobData(null),
      requestExportJobStatus: requestStatus,
    };
  };

  return <exportJobContext.Provider value={getJobContext()}>{children}</exportJobContext.Provider>;
};

export { WithExportJob };
