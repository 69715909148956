import { DeliveryMethod } from '../../../models/ExportJobsAggregate/ExportJobDelivery';
import { FormatExportJob } from '../../../models/ExportJobsAggregate/ExportJobFormat';
import { PeriodType } from '../../../models/ExportJobsAggregate/ExportJobPeriodRange';
import { DEFAULT_CRON_VALUE } from './../../../features/ExportJobsContainer/ExportJobHistory/constants';
import { ExportJobFormViewModel } from './../../../models/ExportJobsAggregate/ExportJobForm';

export const USER_EMAILS_LIST_SEPARATOR = ',';
export const CRON_FIELD_VALIDATION_REGEXP =
  /^([\d\-,/]+) ([\d\-,/]+) ([\d\-,/*]+|\*) ([\d\-,/*]+|\*) ([\d\-,/*]+|\*)$/;

export const defaultValueEditExportJobFields: ExportJobFormViewModel = {
  cronExpression: DEFAULT_CRON_VALUE,
  dataAuthorizationRoleId: null,
  dataAuthorizationRoleName: '',
  deliveryMethod: DeliveryMethod.Ftp,
  format: FormatExportJob.CSV,
  jobName: '',
  provider: null,
  enabled: false,
  periodType: PeriodType.DAY,
  periodOffsetDays: 1,
  report: null,
  reportName: '',
  userEmails: [],
  activeDateRange: null,
};
