import { Report, VisualDescriptor } from 'powerbi-client';
import { IBasicFilter } from 'powerbi-models';
import { PowerBiTargetDetection } from './powerBiTargetDetection';

export class PowerBiHelper {
  public static async getSlicersValues(visuals: VisualDescriptor[]): Promise<string[][]> {
    return await Promise.all(visuals.map((v) => PowerBiHelper.getSlicerValues(v)));
  }

  public static async setSlicersValues(visuals: VisualDescriptor[], values: string[][]): Promise<void> {
    await Promise.all(visuals.map((v, i) => PowerBiHelper.setSlicerValues(v, values[i])));
  }

  public static async getFilterValues(report: Report): Promise<Map<string, string>> {
    const page = await report.getActivePage();
    const filters = await page.getFilters();

    return filters.reduce((acc, f) => {
      const column = PowerBiTargetDetection.getFilterKey(f.target);

      acc.set(column, JSON.stringify(f));

      return acc;
    }, new Map<string, string>());
  }

  public static async determineControls(
    report: Report,
    controlTitles: string[]
  ): Promise<(VisualDescriptor | undefined)[]> {
    const page = await report.getActivePage();
    const visuals = await page.getVisuals();

    return controlTitles.map((title) => visuals.find((v) => v.title === title));
  }

  public static async setFilterValues(report: Report, values: Map<string, string>): Promise<void> {
    const page = await report.getActivePage();
    const filters = await page.getFilters();

    const newFilters = filters.map((f) => {
      const key = PowerBiTargetDetection.getFilterKey(f.target);
      const newFilter = values.get(key);

      return newFilter ? JSON.parse(newFilter) : f;
    });

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    await page.setFilters(newFilters);
  }

  private static async getSlicerValues(visual: VisualDescriptor): Promise<string[]> {
    const slicerState = await visual.getSlicerState();

    // No values selected
    if (slicerState.filters.length === 0) {
      return [];
    }

    const basicFilter = slicerState.filters[0] as IBasicFilter;

    return basicFilter.values.map((v) => v.toString());
  }

  private static async setSlicerValues(visual: VisualDescriptor, values: string[]): Promise<void> {
    const slicerState = await visual.getSlicerState();

    if (values.length > 0) {
      // Initialize the filters array if it doesn't exist
      slicerState.filters = [
        {
          $schema: 'http://powerbi.com/product/schema#basic',
          target: slicerState.targets?.at(0),
          filterType: 1,
          operator: 'In',
          values,
        },
      ] as IBasicFilter[];
    } else {
      slicerState.filters = [];
    }

    await visual.setSlicerState(slicerState);
  }
}
