import React, { useEffect, useState } from 'react';
import { TripsService } from '../../services/TripsService';

import { getTranslation } from '../../utils/getTranslation';
import { Button, Header, Message } from 'semantic-ui-react';
import { Link, useParams } from 'react-router-dom';
import { pathTickets } from '../../routes/constants';
import ViewTripWrapper from '../../features/TripContainer/ViewTrip/ViewTripWrapper';
import { TripViewModel } from '../../models/TripAggregate/Trip';
import { mapTripViewModel } from '../../features/TripContainer/ViewTrip/viewTripMapper';
import FullSizeLoader from '../../shared/view/Loader/FullSizeLoader';
import { FetchError } from '../../utils/errorHandler';
import { API_REQUEST_CODE } from '../../utils/APIHandlers';

interface Props {
  location: Location;
}

const TripPage: React.FC<Props> = (props) => {
  const [trip, setTrip] = useState<TripViewModel | null>(null);
  const [isLoaderShown, setIsLoaderShown] = useState(false);
  const [error, setError] = useState('');

  const { id } = useParams<{ id?: string }>();

  useEffect(() => {
    if (id) {
      void getTicket(id);
    }
  }, [props.location]);

  const getTicket = async (id: string) => {
    setIsLoaderShown(true);
    try {
      const ticketsService: TripsService = new TripsService();
      const response = await ticketsService.getById(id);
      const data = response.result;
      const currentTrip = mapTripViewModel(data);
      setTrip(currentTrip);
    } catch (error) {
      const statusCode = error instanceof FetchError ? error.response.status : undefined;
      if (statusCode === API_REQUEST_CODE.NOT_FOUND) {
        setError(getTranslation('TripAuditInfo_TripError'));
      } else {
        setError(getTranslation('CommonError'));
      }

      setTrip(null);
    } finally {
      setIsLoaderShown(false);
    }
  };

  return (
    <div className="container">
      {isLoaderShown ? (
        <FullSizeLoader />
      ) : (
        <>
          {trip ? (
            <ViewTripWrapper trip={trip} />
          ) : (
            <>
              <Header as="h1">{getTranslation('TripAuditInfo_Tickets')}</Header>
              <Message error>{error}</Message>
              <Button primary as={Link} to={pathTickets} size="small">
                {getTranslation('TicketSearch')}
              </Button>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default TripPage;
