import React, { useContext, useState } from 'react';

import { WithApolloClient } from '../../../features/WithApolloClient/WithApolloClient';
import { userContext } from '../../store/userContext';
import { PetUserFormViewModel } from '../../../models/PetUsersAggregate/PetUser';
import { PetUsersService } from '../../../services/PetUsersService';
import {
  defaultCreatePetUserContextValue,
  CreatePetUserContextProps,
  createPetUserContext,
} from '../../store/PetUser/createPetUserContext';
import { mapCreatePetUser } from '../../../features/AdministrationContainer/PetUsers/petUsersMapper';
import { useNotification, useNotificationReturn } from '../../hooks/notificationHook';
import { getTranslation } from '../../../utils/getTranslation';
import { FetchError, getFetchErrorMessage } from '../../../utils/errorHandler';
import { ErrorFetchResponse } from '../../../api/rest/models/RestApiResponse';
import { API_REQUEST_CODE } from '../../../utils/APIHandlers';

interface Props {
  children: React.ReactChild;
}

const CreatePetUser: React.FC<Props> = ({ children }) => {
  const { email } = useContext(userContext);
  const [requestCreatePetUserMessage, setRequestCreatePetUserMessage] = useState<string>(
    defaultCreatePetUserContextValue.requestCreatePetUserMessage
  );
  const [
    { notificationStatus: requestCreatePetUserStatus },
    {
      setNotRequestedNotificationStatus: setNotRequestedCreatePetUser,
      setRequestedNotificationStatus: setRequestedCreatePetUser,
      setSuccessNotificationStatus: setSuccessCreatePetUser,
      setFailNotificationStatus: setFailCreatePetUser,
    },
  ]: useNotificationReturn = useNotification();

  const getUIErrorMessageByStatusCode = (email: string, errorFetchResponse?: ErrorFetchResponse): string => {
    const statusCode = errorFetchResponse?.response?.status ?? -1;

    switch (statusCode) {
      case API_REQUEST_CODE.CONFLICT:
        return getTranslation('User_Already_Registered_Error_Message').replace('{0}', email);
      case API_REQUEST_CODE.BAD_REQUEST:
        return getFetchErrorMessage(errorFetchResponse as ErrorFetchResponse);
      default:
        return getTranslation('CommonError');
    }
  };

  const createPetUser = async (data: PetUserFormViewModel): Promise<string | undefined | void> => {
    try {
      const userService: PetUsersService = new PetUsersService();
      setRequestedCreatePetUser();

      await userService.create(mapCreatePetUser(data, email));

      setSuccessCreatePetUser();
      setRequestCreatePetUserMessage(
        `${getTranslation('PetUsers_User')} ${data.firstName} ${data.lastName} ${getTranslation(
          'successMessage'
        )}`
      );
    } catch (error) {
      const errorFetchResponse = error instanceof FetchError ? error : undefined;
      const errorMessage = getUIErrorMessageByStatusCode(data.email, errorFetchResponse);
      setFailCreatePetUser();

      console.error(errorMessage);
      return errorMessage;
    }
  };

  const clearCreatePetUserState = () => {
    setNotRequestedCreatePetUser();
    setRequestCreatePetUserMessage(defaultCreatePetUserContextValue.requestCreatePetUserMessage);
  };

  const getCreatePetUserContext = (): CreatePetUserContextProps => {
    return {
      ...defaultCreatePetUserContextValue,
      createPetUser,
      requestCreatePetUserMessage,
      requestCreatePetUserStatus,
      clearCreatePetUserState,
    };
  };

  return (
    <createPetUserContext.Provider value={getCreatePetUserContext()}>
      {children}
    </createPetUserContext.Provider>
  );
};

const WithCreatePetUser = WithApolloClient(CreatePetUser);

export { WithCreatePetUser };
