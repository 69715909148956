import { API } from 'aws-amplify';

import { ErrorFetchResponse } from '../api/rest/models/RestApiResponse';
import { FetchError } from './errorHandler';

export let savedLogout = (): Promise<void> => {
  console.log('API was not initialized. Logout function is missing.');
  return Promise.resolve();
};

export const initializeAPI = (logout: () => Promise<void>): void => {
  savedLogout = logout;
};

export const APIFetch = async <ResponseType>(
  method: 'post' | 'put' | 'get' | 'del',
  apiName: string,
  path: string,
  init: { [key: string]: any }
): Promise<ResponseType> => {
  try {
    return (await API[method](apiName, path, init)) as Promise<ResponseType>;
  } catch (e) {
    throw new FetchError(e as ErrorFetchResponse);
  }
};

export type NotRequested = 'NOT_REQUESTED';
export type Requested = 'REQUESTED';
export type RequestSuccess = 'REQUEST_SUCCESS';
export type RequestFailure = 'REQUEST_FAILURE';
export type RequestStatus = NotRequested | Requested | RequestSuccess | RequestFailure;

export const API_REQUEST_STATUS: {
  NOT_REQUESTED: NotRequested;
  REQUESTED: Requested;
  REQUEST_SUCCESS: RequestSuccess;
  REQUEST_FAILURE: RequestFailure;
} = {
  NOT_REQUESTED: 'NOT_REQUESTED',
  REQUESTED: 'REQUESTED',
  REQUEST_SUCCESS: 'REQUEST_SUCCESS',
  REQUEST_FAILURE: 'REQUEST_FAILURE',
};

export type Success = 200;
export type Accepted = 202;
export type BadRequest = 400;
export type Unauthorized = 401;
export type Forbidden = 403;
export type NotFound = 404;
export type Conflict = 409;
export type InternalServerError = 500;

export const API_REQUEST_CODE: {
  SUCCESS: Success;
  ACCEPTED: Accepted;
  BAD_REQUEST: BadRequest;
  UNAUTHORIZED: Unauthorized;
  FORBIDDEN: Forbidden;
  NOT_FOUND: NotFound;
  CONFLICT: Conflict;
  INTERNAL_SERVER_ERROR: InternalServerError;
} = {
  SUCCESS: 200,
  ACCEPTED: 202,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  CONFLICT: 409,
  INTERNAL_SERVER_ERROR: 500,
};

export const getRequestStatuses = (status: RequestStatus): { [key in RequestStatus]: boolean } => ({
  NOT_REQUESTED: status === API_REQUEST_STATUS.NOT_REQUESTED,
  REQUESTED: status === API_REQUEST_STATUS.REQUESTED,
  REQUEST_SUCCESS: status === API_REQUEST_STATUS.REQUEST_SUCCESS,
  REQUEST_FAILURE: status === API_REQUEST_STATUS.REQUEST_FAILURE,
});
