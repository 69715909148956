import React, { useContext, useState, useEffect, useMemo, useCallback } from 'react';
import { Form, Radio, Message, Grid, Button, Divider } from 'semantic-ui-react';
import { CheckboxProps } from 'semantic-ui-react/dist/commonjs/modules/Checkbox';
import { Controller, useForm, useFormState } from 'react-hook-form';
import { Prompt } from 'react-router-dom';
import { Location as HistoryLocation } from 'history';
import { isDesktop, isMobile } from 'react-device-detect';

import ReportsField from './Fields/ReportsField';
import PeriodField from './Fields/PeriodField';
import ReportTypeField from './Fields/ReportTypeField';
import SftpProviderField from './Fields/SftpProviderField';
import UserEmailsField from './Fields/UserEmailsField';
import DataAuthorizationRolesField from './Fields/DataAuthorizationRolesField';
import { getRulePeriodIdRegister, getRuleDatePickerRegister, getRuleCronRegister } from './validators';
import { defaultValueEditExportJobFields } from './constants';
import { ExportJobFormViewModel } from '../../../models/ExportJobsAggregate/ExportJobForm';
import InputWithInfo from '../../PetForm/InputWithInfo';
import { getRequiredFieldRegister } from '../../PetForm/helper';
import { getTranslation } from '../../../utils/getTranslation';
import {
  DeliveryMethod,
  getDeliveryMethodStatuses,
} from '../../../models/ExportJobsAggregate/ExportJobDelivery';
import { getPeriodTypeStatuses, PeriodType } from '../../../models/ExportJobsAggregate/ExportJobPeriodRange';
import CronField from '../../../shared/controls/CronField';
import DatePickerField from '../../../shared/controls/DatePickerField';
import ShortFieldDescription from '../../../shared/view/ShortFieldDescription';
import { createExportJobContext } from '../../../shared/store/createExportJobContext';
import { sftpProvidersContext } from '../../../shared/store/sftpProvidersContext';
import { getRequestStatuses } from '../../../utils/APIHandlers';
import FormFieldLabel from '../../../shared/view/FormFieldLabel/FormFieldLabel';
import { getIsAllowLeave } from './helpers';

import './styles.scss';

interface CreateExportJobFormProps {
  onSubmit: (submitData: ExportJobFormViewModel) => void;
  defaultData?: ExportJobFormViewModel;
  isEdit?: boolean;
}

const CreateExportJobForm: React.FC<CreateExportJobFormProps> = (props) => {
  const { onSubmit, defaultData, isEdit } = props;
  const { notificationCreateJob, handleOpenLeaveCreateJob, leaveDirection, requestCreateJobStatus } =
    useContext(createExportJobContext);
  const { requestSftpProvidersStatus } = useContext(sftpProvidersContext);
  const [deliveryMethod, setDeliveryMethod] = useState<DeliveryMethod>(
    (defaultData && defaultData?.deliveryMethod) || DeliveryMethod.Ftp
  );
  const [customResourcePeriod, setCustomResourcePeriod] = useState<PeriodType>(PeriodType.DAY);
  const requiredFieldRegister = getRequiredFieldRegister();
  const rulePeriodIdRegister = getRulePeriodIdRegister();
  const ruleDatePickerRegister = getRuleDatePickerRegister();
  const ruleCronRegister = getRuleCronRegister();
  const {
    register,
    getValues,
    setValue,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm<ExportJobFormViewModel>({
    defaultValues: defaultData || defaultValueEditExportJobFields,
  });
  const periodType = watch('periodType');
  const { isDirty } = useFormState({
    control,
  });
  const mobileDivider = isMobile && <Divider />;
  const desktopDivider = isDesktop && <Divider />;
  const requestCreateJob = useMemo(
    () => getRequestStatuses(requestCreateJobStatus),
    [requestCreateJobStatus]
  );
  const requestSftpProviders = useMemo(
    () => getRequestStatuses(requestSftpProvidersStatus),
    [requestSftpProvidersStatus]
  );
  const notificationCreateJobStatus = useMemo(
    () => getRequestStatuses(notificationCreateJob),
    [notificationCreateJob]
  );
  const deliveryMethodType = useMemo(() => getDeliveryMethodStatuses(deliveryMethod), [deliveryMethod]);
  const deliveryMethodFormValue = getValues('deliveryMethod');
  const deliveryMethodValue = useMemo(
    () => getDeliveryMethodStatuses(deliveryMethodFormValue),
    [deliveryMethodFormValue]
  );
  const chosenPeriodType = useMemo(() => getPeriodTypeStatuses(customResourcePeriod), [customResourcePeriod]);

  useEffect(() => {
    periodType !== getValues('periodType') && setValue('periodType', periodType);
  }, [periodType, setValue, getValues]);

  const onLeave = (direction: HistoryLocation): boolean => {
    return getIsAllowLeave({
      isDirty,
      leaveDirection,
      isExportJobCreated: requestCreateJob.REQUEST_SUCCESS,
      callback: () => handleOpenLeaveCreateJob(direction),
    });
  };

  const onSubmitForm = useCallback(
    (event: React.FormEvent<HTMLFormElement>): void => {
      void handleSubmit(onSubmit)(event);
    },
    [onSubmit]
  );

  return (
    <>
      <Prompt when={true} message={onLeave} />
      <Form onSubmit={onSubmitForm} data-testid="createExportJob-form">
        {notificationCreateJobStatus.REQUEST_SUCCESS && (
          <Message
            visible
            success
            content={
              isEdit
                ? getTranslation('Update_Job_message_SUCCESS')
                : getTranslation('Create_Job_message_SUCCESS')
            }
            data-testid="message-createExportJob-positive"
          />
        )}

        <Grid>
          <Grid.Row>
            <Grid.Column mobile={16} tablet={12} computer={10} largeScreen={7} widescreen={7}>
              <InputWithInfo
                required
                label={getTranslation('Edit_ExportJob_JobName')}
                register={register('jobName', requiredFieldRegister)}
                error={errors?.jobName}
                dataTestId="exportJob-name-field"
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <Grid className="container-reportTypeFieldWrapper">
          <Grid.Row>
            <Grid.Column tablet={16} computer={12} largeScreen={10} widescreen={10}>
              <Controller
                render={({ field: { onChange } }) => (
                  <ReportsField
                    required
                    errors={errors}
                    getValues={getValues}
                    onChange={(value: string, text: string) => {
                      setValue('reportName', text);
                      onChange(value);
                    }}
                    dataTestId="exportJob-reports-field"
                  />
                )}
                name="report"
                control={control}
                rules={requiredFieldRegister}
              />
            </Grid.Column>
            <Grid.Column tablet={16} computer={16} largeScreen={6} widescreen={6}>
              <Controller
                render={({ field: { onChange } }) => (
                  <ReportTypeField
                    onChange={onChange}
                    getValues={getValues}
                    label={getTranslation('Edit_ExportJob_ReportType')}
                  />
                )}
                name="format"
                control={control}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>

        {mobileDivider}

        <Grid className="container-periodFieldWrapper">
          <Grid.Row>
            <Grid.Column tablet={16} computer={16} largeScreen={10} widescreen={10}>
              <Controller
                render={({ field: { onChange } }) => (
                  <PeriodField
                    label={getTranslation('Edit_ExportJob_Period')}
                    customResourcePeriod={customResourcePeriod}
                    setCustomResourcePeriod={setCustomResourcePeriod}
                    onChange={onChange}
                    defaultValue={getValues('periodType')}
                    dataTestId="exportJob-periodType-field"
                  />
                )}
                name="periodType"
                control={control}
              />
            </Grid.Column>
            {chosenPeriodType[PeriodType.CUSTOM] && (
              <Grid.Column mobile={16} tablet={8} computer={8} largeScreen={4} widescreen={4}>
                <InputWithInfo
                  required
                  label={getTranslation('JobHistory_ExportJob_PeriodOfData_Custom')}
                  type="number"
                  register={register('periodOffsetDays', rulePeriodIdRegister)}
                  error={errors?.periodOffsetDays}
                  dataTestId="exportJob-customPeriod-field"
                  className="createExportJobCustomPeriodField"
                />
                <Grid>
                  <Grid.Row>
                    <Grid.Column tablet={12} computer={12} largeScreen={16} widescreen={16}>
                      <ShortFieldDescription
                        content={getTranslation('Edit_ExportJob_CustomPeriod_Description')}
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Grid.Column>
            )}
            {!chosenPeriodType[PeriodType.CUSTOM] && (
              <Grid.Column
                tablet={12}
                computer={12}
                largeScreen={6}
                widescreen={6}
                className="container-shortNotification"
              >
                <ShortFieldDescription
                  dataTestId="exportJob-period-description"
                  content={getTranslation('Edit_ExportJob_Period_Description')}
                />
              </Grid.Column>
            )}
          </Grid.Row>
        </Grid>

        {mobileDivider}

        <Grid className="container-cronFieldWrapper">
          <Grid.Row>
            <Grid.Column tablet={16} computer={16} largeScreen={10} widescreen={10}>
              <Controller
                render={({ field: { onChange } }) => (
                  <CronField
                    label={getTranslation('Edit_ExportJob_Cron')}
                    value={getValues('cronExpression')}
                    clearButton={false}
                    onChange={(value: string) => {
                      onChange(value);
                    }}
                    error={errors?.cronExpression}
                    required
                    dataTestId="exportJob-cron-field"
                    allowedPeriods={['year', 'month', 'week', 'day']}
                    allowedDropdowns={['period', 'months', 'month-days', 'week-days', 'hours', 'minutes']}
                  />
                )}
                name={'cronExpression'}
                control={control}
                rules={ruleCronRegister}
              />
            </Grid.Column>
            <Grid.Column
              tablet={12}
              computer={12}
              largeScreen={6}
              widescreen={6}
              className="container-shortNotification"
            >
              <ShortFieldDescription content={getTranslation('Edit_ExportJob_Cron_Description')} />
            </Grid.Column>
          </Grid.Row>
        </Grid>

        {mobileDivider}

        <Grid className="container-datePickerDataAuthWrapper">
          <Grid.Row>
            <Grid.Column mobile={16} tablet={8} computer={6} largeScreen={5} widescreen={5}>
              <Controller
                render={({ field: { onChange } }) => (
                  <DatePickerField
                    label={getTranslation('Edit_ExportJob_ActivationPeriod')}
                    onChange={(date: [Date | null, Date | null]) => onChange(date)}
                    required
                    isClearable
                    selectsRange
                    error={errors?.activeDateRange}
                    defaultValue={getValues('activeDateRange')}
                    dataTestId="exportJob-activationPeriod-field"
                  />
                )}
                name={'activeDateRange'}
                control={control}
                rules={ruleDatePickerRegister}
              />
            </Grid.Column>
            {isMobile && (
              <Grid.Column
                width={12}
                className="container-shortNotification"
                data-testid="activationPeriod-description"
              >
                <ShortFieldDescription
                  content={getTranslation('Edit_ExportJob_ActivationPeriod_Description')}
                />
              </Grid.Column>
            )}
            <Grid.Column mobile={16} tablet={8} computer={6} largeScreen={5} widescreen={5}>
              <Controller
                render={({ field: { onChange } }) => (
                  <DataAuthorizationRolesField
                    errors={errors}
                    getValues={getValues}
                    onChange={(value: string, text: string) => {
                      setValue('dataAuthorizationRoleName', text);
                      onChange(value);
                    }}
                    required
                    dataTestId="exportJob-dataAuthRoles-field"
                  />
                )}
                name="dataAuthorizationRoleId"
                control={control}
                rules={requiredFieldRegister}
              />
            </Grid.Column>
            {isMobile && (
              <Grid.Column
                width={12}
                className="container-shortNotification"
                data-testid="dataAuthorizationRoleId-description"
              >
                <ShortFieldDescription content={getTranslation('Edit_ExportJob_DataAuthRoles_Description')} />
              </Grid.Column>
            )}
            {isDesktop && (
              <Grid.Column
                tablet={12}
                computer={12}
                largeScreen={6}
                widescreen={6}
                className="container-shortNotification"
                data-testid="activationPeriod-dataAuthorizationRoleId-description"
              >
                <ShortFieldDescription
                  content={getTranslation('Edit_ExportJob_DataAuthRoles_ActivationPeriod_Description')}
                />
              </Grid.Column>
            )}
          </Grid.Row>
        </Grid>

        {mobileDivider}

        <Grid>
          <Grid.Row>
            <Grid.Column tablet={16} computer={12} largeScreen={10} widescreen={10}>
              <Form.Field>
                <FormFieldLabel>{getTranslation('Edit_ExportJob_DeliveryMethod_Wrapper')}</FormFieldLabel>
                <Message className="container-deliveryMethodContentWrapper">
                  <Controller
                    render={({ field: { onChange } }) => (
                      <div className="container-deliveryMethodSwitcherWrapper">
                        <Radio
                          label={getTranslation('Edit_ExportJob_DeliveryMethod_FTP')}
                          checked={deliveryMethodValue[DeliveryMethod.Ftp]}
                          onChange={() => {
                            setDeliveryMethod(DeliveryMethod.Ftp);
                            onChange(DeliveryMethod.Ftp);
                          }}
                          data-testid="exportJob-ftpMethod-control"
                          disabled={requestSftpProviders.REQUESTED}
                        />
                        <Radio
                          label={getTranslation('Edit_ExportJob_DeliveryMethod_EMAIL')}
                          checked={deliveryMethodValue[DeliveryMethod.Email]}
                          onChange={() => {
                            setDeliveryMethod(DeliveryMethod.Email);
                            onChange(DeliveryMethod.Email);
                          }}
                          data-testid="exportJob-emailMethod-control"
                          disabled={requestSftpProviders.REQUESTED}
                        />
                      </div>
                    )}
                    name="deliveryMethod"
                    control={control}
                  />
                  {deliveryMethodType[DeliveryMethod.Ftp] && (
                    <Controller
                      render={({ field: { onChange } }) => (
                        <SftpProviderField
                          onChange={onChange}
                          getValues={getValues}
                          errors={errors}
                          dataTestId="exportJob-provider-field"
                          required
                        />
                      )}
                      name="provider"
                      control={control}
                      rules={requiredFieldRegister}
                    />
                  )}
                  {deliveryMethodType[DeliveryMethod.Email] && (
                    <Controller
                      render={({ field: { onChange } }) => (
                        <UserEmailsField
                          label={getTranslation('Edit_ExportJob_UsersEmails')}
                          onChange={onChange}
                          value={getValues('userEmails')}
                          errors={errors}
                          dataTestId="exportJob-usersEmails-field"
                          required
                        />
                      )}
                      name={'userEmails'}
                      control={control}
                      rules={requiredFieldRegister}
                    />
                  )}
                </Message>
              </Form.Field>
            </Grid.Column>
            <Grid.Column
              tablet={12}
              computer={12}
              largeScreen={6}
              widescreen={6}
              className="container-shortNotification"
            >
              <ShortFieldDescription content={getTranslation('Edit_ExportJob_DeliveryMethod_Description')} />
            </Grid.Column>
          </Grid.Row>
        </Grid>

        {desktopDivider}

        <Grid>
          <Grid.Row>
            <Grid.Column only="computer" width={10}></Grid.Column>
            <Grid.Column tablet={16} computer={16} largeScreen={6} widescreen={6}>
              <div className="container-editExportJobControlWrapper">
                <div className="container-exportJobEnableControl">
                  <Controller
                    render={({ field: { onChange } }) => (
                      <Radio
                        toggle
                        label={getTranslation('PetUsers_Enabled')}
                        defaultChecked={getValues('enabled')}
                        disabled={false}
                        onChange={(_e: React.FormEvent<HTMLInputElement>, { checked }: CheckboxProps): void =>
                          onChange(checked)
                        }
                        data-testid="exportJob-enable-field"
                      />
                    )}
                    name="enabled"
                    control={control}
                  />
                </div>
                <Button
                  data-testid="create-exportJob-btn"
                  content={getTranslation(isEdit ? 'EditJob_Form_Control' : 'Create_ExportJob_Title')}
                  type="submit"
                  primary
                  size="small"
                  disabled={
                    notificationCreateJobStatus.REQUEST_SUCCESS || notificationCreateJobStatus.REQUESTED
                  }
                />
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    </>
  );
};

export default CreateExportJobForm;
