import { TriggerExportJobResponse } from '../api/rest/models/ExportJob';
import { RestApiResponse } from '../api/rest/models/RestApiResponse';
import { PetApi } from '../constants/apiParams';
import { APIFetch } from '../utils/APIHandlers';
import { RawExportJobDTO } from '../api/rest/models/ExportJob/Response/RawExportJobDTO';
import { CreateEditExportJobDTO } from '../api/rest/models/ExportJob/Request/CreateEditExportJobDTO';
import { UpdateEditExportJobDTO } from '../api/rest/models/ExportJob/Request/UpdateEditExportJobDTO';
import {
  ResponseExportJobsListDTO,
  ResponseExportJobHistoryListDTO,
} from '../api/rest/models/ExportJob/ExportJobCollections';

export class ExportJobsService {
  private readonly endpoint: string;

  constructor() {
    this.endpoint = '/v1/tickets/pet/exports';
  }

  public async getAll(): Promise<RestApiResponse<ResponseExportJobsListDTO>> {
    return await APIFetch<RestApiResponse<ResponseExportJobsListDTO>>(
      'get',
      PetApi,
      `${this.endpoint}/all`,
      {}
    );
  }

  public async getJobHistory(id: string): Promise<RestApiResponse<ResponseExportJobHistoryListDTO>> {
    return await APIFetch<RestApiResponse<ResponseExportJobHistoryListDTO>>(
      'get',
      PetApi,
      `${this.endpoint}/${id}/history`,
      {}
    );
  }

  public async getById(id: string): Promise<RestApiResponse<RawExportJobDTO>> {
    return await APIFetch<RestApiResponse<RawExportJobDTO>>('get', PetApi, `${this.endpoint}/${id}`, {});
  }

  public async update(input: UpdateEditExportJobDTO): Promise<RestApiResponse<RawExportJobDTO>> {
    return await APIFetch<RestApiResponse<RawExportJobDTO>>('put', PetApi, `${this.endpoint}`, {
      body: input,
    });
  }

  public async create(input: CreateEditExportJobDTO): Promise<RestApiResponse<RawExportJobDTO>> {
    return await APIFetch<RestApiResponse<RawExportJobDTO>>('post', PetApi, `${this.endpoint}`, {
      body: input,
    });
  }

  public async del(id: string): Promise<RestApiResponse> {
    return await APIFetch<RestApiResponse>('del', PetApi, `${this.endpoint}/${id}`, {});
  }

  public async triggerExportJob(jobId: string): Promise<RestApiResponse<TriggerExportJobResponse>> {
    return await APIFetch<RestApiResponse<TriggerExportJobResponse>>(
      'post',
      PetApi,
      `${this.endpoint}/schedule`,
      {
        body: { jobId },
      }
    );
  }
}
