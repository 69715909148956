import { GtrStatusValue } from '../../../../api/rest/models/Trip/Ticket/GtrStatusValue';
import { applicationLabels } from '../../../../glossary';

type GetTicketStatusType = {
  [key in GtrStatusValue]: { text: keyof (typeof applicationLabels)['en']; bgColor: string };
};

export const getCouponStatusCode = (): {
  [key: string]: string;
} => ({
  A: 'TicketCard_AirportControl',
  C: 'TicketCard_CheckedIn',
  E: 'TicketCard_ExchangedReissued',
  F: 'TicketCard_FlownUsed',
  G: 'TicketCard_ConvertedToFim',
  I: 'TicketCard_IrregularOperations',
  L: 'TicketCard_LiftedBoarded',
  N: 'TicketCard_CouponNotification',
  O: 'TicketCard_OpenForUse',
  P: 'TicketCard_Printed',
  R: 'TicketCard_Refunded',
  S: 'TicketCard_Suspended',
  T: 'TicketCard_PaperTicket',
  U: 'TicketCard_Unavailable',
  V: 'TicketCard_Void',
  X: 'TicketCard_PrintExchange',
  Z: 'TicketCard_Closed',
  UNK: 'Unknown',
});

export const getTicketStatus: GetTicketStatusType = {
  [GtrStatusValue.Unknown]: { text: 'TicketCard_Unknown', bgColor: '' },
  [GtrStatusValue.Voided]: { text: 'TicketCard_Voided', bgColor: 'light-orange' },
  [GtrStatusValue.OpenAvailable]: { text: 'TicketCard_OpenAvailable', bgColor: 'green' },
  [GtrStatusValue.Exchanged]: { text: 'TicketCard_Exchanged', bgColor: 'pink' },
  [GtrStatusValue.Suspended]: { text: 'TicketCard_Suspended', bgColor: 'orange' },
  [GtrStatusValue.UsedFlown]: { text: 'TicketCard_UsedFlown', bgColor: 'blue' },
  [GtrStatusValue.Refunded]: { text: 'TicketCard_Refunded', bgColor: 'sky' },
  [GtrStatusValue.Expired]: { text: 'TicketCard_Expired', bgColor: 'red' },
  [GtrStatusValue.AirportControl]: { text: 'TicketCard_AirportControl', bgColor: 'purple' },
  [GtrStatusValue.Lost]: { text: 'TicketCard_Lost', bgColor: 'red' },
  [GtrStatusValue.Voucher]: { text: 'TicketCard_Voucher', bgColor: 'green' },
  [GtrStatusValue.UnusedAvailable]: { text: 'TicketCard_UnusedAvailable', bgColor: 'green' },
  [GtrStatusValue.Restricted]: { text: 'TicketCard_Restricted', bgColor: '' },
  [GtrStatusValue.Exception]: { text: 'TicketCard_Exception', bgColor: '' },
  [GtrStatusValue.ServiceFee]: { text: 'TicketCard_ServiceFee', bgColor: '' },
  [GtrStatusValue.Migrated]: { text: 'TicketCard_Migrated', bgColor: '' },
  [GtrStatusValue.Inactive]: { text: 'TicketCard_Inactive', bgColor: '' },
  [GtrStatusValue.BtaUatp]: { text: 'TicketCard_BtaUatp', bgColor: 'sky' },
  [GtrStatusValue.NotApplicable]: { text: 'TicketCard_NotApplicable', bgColor: '' },
};
