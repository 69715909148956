import { v4 as uuid } from 'uuid';

import {
  IdentificationViewModel,
  TravelerViewModel,
  TripViewModel,
} from '../../../models/TripAggregate/Trip';
import {
  NewTicketExchangeInfoViewModel,
  OriginalTicketExchangeInfoViewModel,
  CouponViewModel,
  TripTicketViewModel,
} from '../../../models/TripAggregate/TripTicket';
import { getTranslation } from '../../../utils/getTranslation';
import { GDSStatus } from './helper';
import { getCouponStatusCode } from './TicketCard/helper';
import { GtrTripCanonicalModelDTO } from '../../../api/rest/models/Trip/GtrTripCanonicalModelDTO';
import { IdentificationDTO } from '../../../api/rest/models/Trip/IdentificationDTO';
import { CouponDTO } from '../../../api/rest/models/Trip/Ticket/CouponDTO';
import { GtrStatusValue } from '../../../api/rest/models/Trip/Ticket/GtrStatusValue';
import { NewTicketExchangeInfoDTO } from '../../../api/rest/models/Trip/Ticket/NewTicketExchangeInfoDTO';
import { OriginalTicketExchangeInfoDTO } from '../../../api/rest/models/Trip/Ticket/OriginalTicketExchangeInfoDTO';
import { TripTicketDTO } from '../../../api/rest/models/Trip/Ticket/TripTicketDTO';
import { TravelerDTO } from '../../../api/rest/models/Trip/TravelerDTO';

export const DEFAULT_COUPON_STATUS = 'UNK';
const couponStatusCode = getCouponStatusCode();

export const getValueOrDefault = (value?: string | number | null): string => {
  let valueToConvert = value;

  if (typeof valueToConvert === 'number') {
    valueToConvert = valueToConvert.toFixed(2);
  }

  return (valueToConvert && String(valueToConvert)) || '-';
};

export const getDateOrDefault = (value?: string | null): Date | undefined => {
  return (value && new Date(value)) || undefined;
};

export const mapTripIdentification = (data?: IdentificationDTO | null): IdentificationViewModel => ({
  accountCountryCode: getValueOrDefault(data?.accountCountryCode),
  accountId: getValueOrDefault(data?.accountId),
  creationOfficeId: getValueOrDefault(data?.creationOfficeId),
  customerName: getValueOrDefault(data?.customerName),
  customerNumber: getValueOrDefault(data?.customerNumber),
  gds: (data?.gds && GDSStatus[data.gds]) || '-',
  globalCustomerNumber: getValueOrDefault(data?.globalCustomerNumber),
  isCancelled: data?.isCancelled ?? false,
  pseudoCityCode: getValueOrDefault(data?.pseudoCityCode),
  recordLocator: getValueOrDefault(data?.recordLocator),
});

export const mapCoupons = (coupons?: CouponDTO[] | null): CouponViewModel[] =>
  (coupons &&
    coupons.map(
      (coupon): CouponViewModel => ({
        id: String(uuid()),
        departureDateTime: getDateOrDefault(coupon.departureDateTime),
        departureAirportCode: getValueOrDefault(coupon.departureAirportCode),
        departureAirportCountryCode: getValueOrDefault(coupon.departureAirportCountryCode),
        arrivalDateTime: getDateOrDefault(coupon.arrivalDateTime),
        arrivalAirportCode: getValueOrDefault(coupon.arrivalAirportCode),
        arrivalAirportCountryCode: getValueOrDefault(coupon.arrivalAirportCountryCode),
        marketingFlightNumber: getValueOrDefault(coupon.marketingFlightNumber),
        vendorLocator: getValueOrDefault(coupon.vendorLocator),
        ticketDesignator: getValueOrDefault(coupon.ticketDesignator),
        couponStatus: getTranslation(couponStatusCode[coupon.couponStatus || DEFAULT_COUPON_STATUS]),
        fareBasisCode: getValueOrDefault(coupon.fareBasisCode),
        classOfService: getValueOrDefault(coupon.classOfService),
        classOfServiceDescription: getValueOrDefault(coupon.classOfServiceDescription),
      })
    )) ||
  [];

export const mapTripTicketTravelers = (
  travelers?: TravelerDTO[] | null,
  travelerNumber?: string | null
): TravelerViewModel[] =>
  (travelers &&
    travelerNumber &&
    travelers
      .filter((traveler) => travelerNumber === traveler.travelerNumber)
      .map(
        (traveler): TravelerViewModel => ({
          firstName: getValueOrDefault(traveler.firstName),
          lastName: getValueOrDefault(traveler.lastName),
          middleName: getValueOrDefault(traveler.middleName),
          primaryEmailAddress: getValueOrDefault(traveler.primaryEmailAddress),
          travelerNumber: getValueOrDefault(traveler.travelerNumber),
        })
      )) ||
  [];

export const mapTripNewTicket = (
  data?: NewTicketExchangeInfoDTO | null
): NewTicketExchangeInfoViewModel | undefined =>
  (data && {
    fullTicketNumber: `${getValueOrDefault(data.ticketStock)} ${getValueOrDefault(data.ticketNumber)}`,
    recordLocator: getValueOrDefault(data.recordLocator),
    firstName: getValueOrDefault(data.firstName),
    lastName: getValueOrDefault(data.lastName),
    issueDate: getDateOrDefault(data.issueDate),
    expiryDate: getDateOrDefault(data.expiryDate),
  }) ||
  undefined;

export const mapTripOriginTicket = (
  data?: OriginalTicketExchangeInfoDTO | null
): OriginalTicketExchangeInfoViewModel | undefined =>
  (data && {
    commonAmount: `${getValueOrDefault(data.amount?.amount)} ${getValueOrDefault(data.amount?.currency)}`,
    issueDate: getDateOrDefault(data.issueDate),
    fullTicketNumber: `${getValueOrDefault(data.ticketStock)} ${getValueOrDefault(data.ticketNumber)}`,
    recordLocator: getValueOrDefault(data.recordLocator),
  }) ||
  undefined;

export const mapTripTickets = (
  tickets: TripTicketDTO[],
  tripTravelers?: TravelerDTO[] | null
): TripTicketViewModel[] =>
  tickets.map((ticket): TripTicketViewModel => {
    const fullTicketNumber =
      ((ticket.ticketStock && `${ticket.ticketStock} `) || '') + (ticket.ticketNumber ?? '');
    return {
      creditAmount: {
        amount: getValueOrDefault(ticket.creditAmount?.amount),
        currency: getValueOrDefault(ticket.creditAmount?.currency),
        description: getValueOrDefault(ticket.creditAmount?.description),
      },
      expiryDate: getDateOrDefault(ticket.expiryDate),
      fareAmount: getValueOrDefault(ticket.fareAmount),
      fareCalculation: getValueOrDefault(ticket.fareCalculation),
      fareCurrency: getValueOrDefault(ticket.fareCurrency),
      invoiceNumber: getValueOrDefault(ticket.invoiceNumber),
      isConjunctive: ticket.isConjunctive ?? false,
      isRefundable: ticket.isRefundable,
      lowFareReasonCode: getValueOrDefault(ticket.lowFareReasonCode),
      ticketBranchPcc: getValueOrDefault(ticket.ticketBranchPcc),
      ticketIssueDate: getDateOrDefault(ticket.ticketIssueDate),
      travelers: mapTripTicketTravelers(tripTravelers, ticket.travelerNumber),
      fullTicketNumber: fullTicketNumber,
      tourCode: getValueOrDefault(ticket.tourCode),
      endorsements: getValueOrDefault(ticket.endorsements && ticket.endorsements.join(',')),
      validatingCarrier: getValueOrDefault(ticket.validatingCarrier),
      formOfPaymentsNumber: getValueOrDefault(ticket.formOfPayments?.paymentNumber),
      gtrStatus: ticket.gtrStatus?.value ?? GtrStatusValue.Unknown,
      baseFaresAmount: getValueOrDefault(ticket.fares?.[0]?.amount),
      commonAmount: getValueOrDefault(
        ticket.taxes?.reduce((initial, { fare }) => initial + Number(fare?.amount ?? 0), 0).toFixed(2)
      ),
      exchangeInfo: {
        newTicket: mapTripNewTicket(ticket.exchangeInfo?.newTicket),
        originalTicket: mapTripOriginTicket(ticket.exchangeInfo?.originalTicket),
      },
      coupons: mapCoupons(ticket.coupons),
    };
  });

export const mapTripViewModel = (data: GtrTripCanonicalModelDTO): TripViewModel => ({
  createdBy: data.createdBy,
  createdDate: new Date(data.createdDate),
  expirationFromDate: new Date(data.expirationFromDate),
  identification: mapTripIdentification(data.identification),
  tickets: (data.tickets && mapTripTickets(data.tickets, data.travelers)) || [],
  updatedBy: getValueOrDefault(data.updatedBy),
  updatedDate: getDateOrDefault(data.updatedDate),
});
