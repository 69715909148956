export const applicationLabels = {
  en: {
    DataAuthorizationRole: 'Data authorization role',
    FeatureGroup: 'Feature group',
    FieldRequired: 'This field is required',
    FieldAllowDaysOfYear: 'Allowed value is between 1 and 365',
    successMessage: 'has been added successfully.',
    successEditMessage: 'has been updated successfully.',
    deleteMessage: 'was successfully deleted',
    downloadedMessage: 'was successfully downloaded.',
    Success: 'Success',
    Error: 'Oops. Something went wrong. Please try again later.',
    Empty: 'There is no data for your request.',
    Empty_Word: 'Empty...',
    Download: 'Download',
    ViewDetails: 'View details',
    Edit: 'Edit',
    Cancel: 'Cancel',
    Confirm: 'Confirm',
    Save: 'Save',
    Search: 'Search',
    Submit: 'Submit',
    Clear: 'Clear',
    Retry: 'Retry',
    Retry_Now: 'Retry now',
    Enable: 'Enable',
    Disable: 'Disable',
    Enabled: 'Enabled',
    Disabled: 'Disabled',
    Welcome: 'Welcome',
    Document: 'Document',
    Run: 'Run',
    BySBD: 'By',
    AtTime: 'At',
    Day_Word: 'day(s)',
    RetryDeleteNow: 'Retry delete now',
    RetryEnableNow: 'Retry enable now',
    RetryDisableNow: 'Retry disable now',
    RetryTriggerExportJobNow: 'Retry run now',
    RunWarningContent: 'Are you sure to immediately run',
    EnableWarningContent: 'Are you sure you want to enable?',
    DisableWarningContent: 'Are you sure you want to disable?',
    SaveWarningContent: 'Are you sure you want to save changes?',
    SaveWarningTitle: 'Save editing',
    RetryDeleteWarningContent: 'Are you sure you want to retry to delete',
    DeleteWarningContent: 'Are you sure you want to delete',
    CreateJobWarningContent: 'Are you sure you want to create new schedule?',
    UpdateJobWarningContent: 'Are you sure you want to update schedule?',
    Yes: 'Yes',
    No: 'No',
    CancelWarningContent: 'Are you sure you want to leave without saving changes?',
    Delete: 'Delete',
    Reports: 'Reports',
    No_Reports: 'No reports',
    SecurityManagerID: 'Security Manager ID',
    LCNCustomerNumber: 'LCN (Customer number)',
    LocalCustomerNumber: 'Local Customer Number',
    PCC: 'PCC',
    AccessForbidden: 'Access Forbidden',
    Report: 'Report',
    CommonError: 'Something is wrong, please try again later',
    AlreadyExists: 'already exists',
    NotFound: 'not found',
    CommonEmailError: 'Incorrect email',
    CommonEmailPasswordError: 'Incorrect username or password',
    RangeDatePickerValidation: 'Entered value does not match start, end dates format',
    EmailValidation: 'Entered value does not match email format',
    PasswordValidation:
      'Your password must be at least 8 characters, and include at least one lowercase letter, one uppercase letter, and one number, and one special character',
    App_Loading: 'Loading',
    App_LoadingError: 'Failed to load data sources. Please try again later.',
    Header_Logout: 'Logout',
    Header_Profile: 'Profile',
    Header_Reports: 'Reports',
    Footer_text: `© ${new Date().getFullYear()} BCD Travel, All rights reserved. TripSource® and BCD Travel® are registered trademarks of BCD Group.`,
    Authentication_FooterAllRights: `© ${new Date().getFullYear()} BCD Travel, All rights reserved.`,
    Authentication_FooterTrademarks: 'TripSource® and BCD Travel® are registered trademarks of BCD Group',
    Privacy_policy: 'Privacy policy',
    Terms_and_conditions: 'Terms and conditions',
    CompanyName: 'BCD Travel',
    Logo_ProjectName: 'Ticket Tracker',
    Logo_Slogan: 'Travel smart. Achieve more.',
    Authentication_SignInHeader: 'Welcome to Ticket Tracker',
    Authentication_Email: 'Email',
    Authentication_Password: 'Password',
    Authentication_NewPassword: 'New password',
    Authentication_Login: 'Login',
    Authentication_Change: 'Change',
    Authentication_Code: 'Code',
    Authentication_ForgotPassword: 'Forgot your password?',
    Authentication_PasswordReset: 'Password reset',
    Authentication_BCDSignIn: 'BCD sign in',
    Authentication_EmailSignIn: 'Email sign in',
    Authentication_ChangePassword: 'Change password',
    Authentication_ChangeInfo:
      'You need to update your password because this is the first time you are signing in',
    Authentication_BackToLogin: 'Go back to Login',
    Authentication_ForgotPasswordInfo:
      'Please enter your e-mail address below and we will send a verification code to you.',
    Authentication_VerificationCodeBtn: ' Email me verification code',
    Authentication_GoBack: 'Never mind, go back',
    Authentication_Header: 'First impressions last.',
    Authentication_HeaderInfo1: 'The workforce is changing.',
    Authentication_HeaderInfo2: 'Transform how you manage travel.',
    Authentication_or: 'or',
    MFA_Title: 'Multi-factor Authentication',
    MFA_SubTitle: 'Please check your email and enter a code.',
    MFA_SendCode_SubTitle: 'Please authenticate with a code.',
    MFA_CodeHadBeenSend: 'Code has been sent, please check your email.',
    MFA_CodeHadBeenSend_Valid: 'Code is valid for 15 minutes.',
    MFA_WaitingCode: 'Authentication is in progress',
    MFA_VerificationCode: 'Verifying the code',
    MFA_VerificationCode_Failed: 'Code is wrong or expired. Please, try again.',
    MFA_Resend_Code: 'Send new code',
    MFA_Send_Code: 'Send code',
    MFA_Code: 'MFA Code',
    Error_MFAAuthenticateToken: 'Oops. Something went wrong. Please try to send new code again later.',
    DownloadScheduledReport_Title: 'Your report is ready',
    DownloadScheduledReport_SubTitle: 'Press the button to download the report',
    DownloadScheduledReport_Downloading: 'Downloading',
    DownloadScheduledReport_Error: 'Token has expired.',
    DownloadScheduledReport_Success: 'Report was successfully downloaded.',
    FirstName: 'First name',
    LastName: 'Last name',
    PetUserForm_EmailLogin: 'Email (login)',
    PetUserForm_EndDate: 'End date',
    PetUsers_Details: 'User details',
    PetUsers_Enabled: 'Enabled',
    PetUsers_NotEnabled: 'Not enabled',
    PetUsers_Federated: 'Federated',
    PetUsers_NotFederated: 'Not federated',
    PetUsers_Name: 'Name',
    PetUsers_Email: 'Email',
    PetUsers_User: 'ⓘ User',
    PetUsers_AddUser: 'Add user',
    PetUsers_EditUser: 'Edit user',
    No_PetUsers: 'Users not found',
    No_PetUser: 'User not found',
    DocumentsRepository_Header: 'Document repository',
    DocumentsRepository_LastModified: 'Last modified',
    DocumentsRepository_Size: 'Size',
    Administration_Administration: 'Administration',
    Administration_Users: 'Users',
    Administration_AddUser: 'Add user',
    Administration_FeatureGroups: 'Feature groups',
    Administration_DataAuthorizationRoles: 'Data authorization roles',
    AdministrationTable_Edit: 'Edit',
    AdministrationTable_Disable: 'Disable',
    AdministrationTable_Of: 'of',
    AdministrationTable_Enable: 'Enable',
    FeatureAccessGroupsDetails_ExportJobsManagement: 'Scheduled reports management',
    FeatureAccessGroupsDetails_FeatureAccessGroupManagement: 'Feature group management',
    FeatureAccessGroupsDetails_DataAuthorizationRolesManagement: 'Data authorization management',
    FeatureAccessGroupsDetails_TicketsManagement: 'Tickets management',
    FeatureAccessGroupsDetails_GroupName: 'Feature group name',
    FeatureAccessGroupsDetails_UserManagement: 'User management',
    FeatureAccessGroupsDetails_ReportsPermission: 'Reports permission',
    TableLabel_None: 'None',
    TableLabel_Read: 'Read',
    TableLabel_ReadWrite: 'Read, Write',
    TableLabel_ReadWriteExport: 'Read, Write, Export',
    TableLabel_ReadExport: 'Read, Export',
    Name: 'Name',
    AuditInfo_DataAuthorizationGroup: 'Data authorization group name',
    AuditInfo_EmailLogin: 'Email (login)',
    DateInfo_Created: 'Created',
    DateInfo_By: 'by',
    DateInfo_Updated: 'Updated',
    AuditInfo_Enabled: 'Enabled',
    AuditInfo_Federated: 'Federated',
    FeatureAccessGroupsForm_Name: 'Name',
    FeatureAccessGroupsForm_AddFeatureGroup: 'Add feature group',
    FeatureAccessGroupsForm_EditFeatureGroup: 'Edit feature group',
    FeatureAccessGroupsForm_Administration: 'Administration',
    FeatureAccessGroupsForm_Reports: 'Reports',
    FeatureAccessGroupsForm_Permission: 'Permission',
    FeatureAccessGroupsForm_ReportTypes: 'Report types',
    FeatureAccessGroupsForm_FeatureGroup: 'ⓘ Feature group',
    FeatureAccessGroupsGridHeader_GroupName: 'Group name',
    FeatureAccessGroupsGridHeader_UserManagement: 'User',
    FeatureAccessGroupsGridHeader_TicketsManagement: 'Tickets',
    FeatureAccessGroupsGridHeader_FeatureAccessManagement: 'Feature access',
    FeatureAccessGroupsGridHeader_DataAuthorizationManagement: 'Data authorization',
    FeatureAccessGroupsGridHeader_ScheduledReportsManagement: 'Scheduled reports',
    FeatureAccessGroups_Details: 'Feature group details',
    FeatureAccessGroups_EditGroup: 'Edit feature group',
    No_FeatureAccessGroups: 'Feature access groups not found',
    No_FeatureAccessGroup: 'Feature access group not found',
    DataAuthorizationRoles_RoleName: 'Role name',
    DataAuthorizationRoles_HowDoesItWork: 'How does it work?',
    DataAuthorizationRoles_HowDoesItWorkTextPart1:
      'Use Inclusive toggle to specify the list of accounts that the user is allowed to view. Note that if Inclusive is enabled while the list of values is left empty, this will prohibit access to all accounts.',
    DataAuthorizationRoles_HowDoesItWorkTextPart2:
      'Switch off Inclusive toggle, if you need to specify a list of exception account ids, meaning that the user will be enabled to view all accounts except those specified. If Inclusive toggle is disabled while values list left empty, the user will be allowed to view all accounts.',
    DataAuthorizationRoles_HowDoesItWorkTextPart3:
      'Values in different account types (GCN, SMID, LCN, PCC) are combined by "AND" condition. This means that, for example, if GCN inclusive lists has a value 1001 and SMID inclusive list contain values 222000 and 222044, then the user will only be able to see those tickets which belong to GCN=1001&SMID=22000 or GCN=1001&SMID=222044, while they will not be able to see tickets with GCN=1001 and empty SMID value/other SMID values like 222005.',
    DataAuthorizationRoles_Inclusive: 'Inclusive',
    DataAuthorizationRoles_NotInclusive: 'Not inclusive',
    DataAuthorizationRolesForm_CreateDataAuthorization: 'Create data authorization role',
    DataAuthorizationRolesForm_EditDataAuthorization: 'Edit data authorization role',
    DataAuthorizationRolesForm_RoleName: 'Role name',
    ReturnToTheTable: 'Return to the table',
    Reports_ReportName: 'Report name',
    Reports_DisplayName: 'Display name',
    Reports_Type: 'Report type',
    Report_Type_Analytical: 'Analytical',
    Report_Type_Activity: 'Activity',
    Report_Type_Action: 'Action',
    Report_Type_Info: 'Info',
    Report_Type_Tech: 'Tech',
    Reports_Icon: 'Report icon',
    Reports_Description: 'Description',
    Reports_Paginated: 'Paginated',
    ReportForm_CreateNew: 'Create new report',
    Reports_Report: 'ⓘ Report',
    Reports_newReport: 'Create report',
    Reports_editReport: 'Edit report',
    Reports_Export: 'Export to PDF',
    Report_Analytical_category: 'Analytical',
    Report_Activity_category: 'Activity',
    Report_Action_category: 'Action',
    Report_Info_category: 'Info',
    Report_Tech_category: 'Tech',
    Report_New_category: 'New report',
    Report_Dashboard_description:
      'Interactive infographs and charts of ticket counts and value by date range',
    Report_Benchmark_description:
      'Benchmark compares the ticket count and values in percentages by ticket status of one account to all BCD accounts of the selected time period',
    Report_ActivitySummary_description:
      'Infographs displaying all past date tickets by status types for each carrier in total count and amounts at the time the report is generated',
    Report_ActivityDetail_description:
      'Ticket details of various ticket statuses based on the dates updated in Ticket Tracker',
    Report_PeriodSummary_description:
      'Summary of client data comparing the previous month ticket count and values to the clients overall total',
    Report_Open_description: 'All tickets with open status for future and past travel dates',
    Report_Exchanged_description:
      'List of ticket details with exchanged status including the new ticket numbers, passenger names and travel dates',
    Report_Expiring_description:
      'List of non-refundable tickets with past travel dates due to expire within a specific date range',
    Report_Refunds_description:
      'List of unused, past dates tickets with refundable fares that can be held for exchange or must be validated before requesting a refund',
    Report_Expired_description: 'List of tickets with an expired status',
    Report_LostValue_description:
      'List of non-refundable tickets with lost value due to suspended status, value less than exchange penalty or carrier doesn`t allow exchanges for separated employees',
    Report_NameChangeExchange_description:
      'List of exchanged tickets where the new document name differs from the original ticket',
    Report_Exception_description:
      'All tickets requiring manual intervention to be updated due to missing or incorrect information',
    DataAuthTableCell_None: 'None',
    DataAuthTableCell_All: 'All',
    Help_SupportTitle: 'Ticket Tracker Support',
    Help_SupportButton: 'Contact Support',
    Help_SupportText:
      'If you have questions about Ticket Tracker, please contact us via the BCD Support Desk',
    RestrictionFilterValue_Search: 'Search',
    RestrictionFilterValue_Selected: 'Selected',
    RecordLocators: 'Record locators',
    RecordLocator: 'Record locator',
    Systems_TicketNumbers: 'Ticket numbers',
    Systems_System: 'System',
    Systems_TriggerTicketEnrichment: 'On-demand ticket enrichment',
    Systems_FormDescription:
      'Please provide a list of ticket numbers or record locators for the tickets you would like to refresh in GDS',
    Systems_RequiredField: 'A list of ticket numbers or record locator are required',
    Systems_SuccessMessage: 'Record locators/Ticket numbers have been added successfully.',
    Systems_SingleInvalid: 'is invalid',
    Systems_Invalid: 'are invalid',
    System_ValidateNumbers: 'Please enter comma or space 13-digit values',
    System_WarningMessage: 'Are you sure to trigger data refresh from GDS for these trips?',
    System_ValidateLetters:
      'Please enter comma or space separated alphanumeric values without special characters',
    Profile_UserName: 'User name',
    NotFoundPage_Header: 'Page is not found',
    NotFoundPage_Text: 'Seems you’ve stumbled upon the page that has been moved, deleted or does not exist.',
    NotFoundPage_HomePage: 'Home page',
    Private_Title: 'No access',
    Private_Text: '403. Access to this resource on the server is denied',
    AccessDeniedMessage_Text: 'Something went wrong. Please, try again later or contact the support.',
    NotFullAccess_Message: 'Please, ask your manager to get an access to the next fields values: ',
    TicketsNumber: 'Ticket number',
    BookingId: 'Booking ID',
    GtrStatus: 'Ticket status',
    InvoiceNumber: 'Invoice number',
    TravelerEmailAddress: 'Traveler email address',
    TicketIssueDate: 'Ticket issue date',
    UpdatedDate: 'Updated date',
    TicketSearch: 'Tickets search',
    TicketsForm_Text: 'Type any complete value then press “Enter” or click “Search”',
    TicketsListPage_WarningMessage:
      'The full result list cannot be loaded, please narrow down the search criteria.',
    TicketsForm_LastNameValidation: 'Please enter last name',
    TicketsListPage_NotResult: 'No matches found. Please refine search.',
    TripAuditInfo_FareAmount: 'Fare amount',
    TripAuditInfo_TicketExpiryDate: 'Ticket expiry date',
    TripAuditInfo_TicketBranchPCC: 'Ticket branch PCC',
    TripAuditInfo_TourCode: 'Tour code',
    TripAuditInfo_CreditAmountAvailable: 'Credit amount available',
    TripAuditInfo_ValidatingCarrier: 'Validating carrier',
    TripAuditInfo_TicketIssueDate: 'Ticket issue date',
    TripAuditInfo_LowFareReasonCode: 'Low fare reason code',
    TripAuditInfo_Endorsements: 'Endorsements',
    TripAuditInfo_ClassOfService: 'Сlass of service',
    TripAuditInfo_ConfirmationNumber: 'Confirmation number',
    TripAuditInfo_Status: 'Status',
    TripAuditInfo_Departure: 'Departure',
    TripAuditInfo_Arrival: 'Arrival',
    TripAuditInfo_CouponStatus: 'Coupon status',
    TripAuditInfo_FareBasisCode: 'Fare basis code',
    TripAuditInfo_TicketDesignator: 'Ticket designator',
    TripAuditInfo_FlightNumber: 'Flight number',
    TripAuditInfo_CreatedBy: 'Created by',
    TripAuditInfo_CreatedDate: 'Created date',
    TripAuditInfo_UpdatedBy: 'Updated by',
    TripAuditInfo_UpdatedDate: 'Updated date',
    TripAuditInfo_CustomerName: 'Customer name',
    TripAuditInfo_AccountId: 'Account ID',
    TripAuditInfo_PseudoCityCode: 'Pseudo city code',
    StatusUpdateDate: 'Status Update Date',
    Cancelled: 'Cancelled',
    GlobalCustomerNumber: 'Global Customer Number',
    TripAuditInfo_CountryCode: 'Country code',
    TripAuditInfo_CreationLocation: 'Creation location',
    TripAuditInfo_CustomerNumber: 'Customer number',
    TripAuditInfo_TicketsSearch: 'Tickets search',
    TripAuditInfo_BookingSource: 'Booking source',
    TripAuditInfo_TripInfo: 'Trip info',
    TripAuditInfo_Ticket: 'Ticket',
    TripAuditInfo_Tickets: 'Tickets',
    TripAuditInfo_TripSegments: 'Trip segments',
    TripAuditInfo_OriginalTicket: 'Original ticket',
    TripAuditInfo_NewTicket: 'New ticket',
    TripAuditInfo_ExchangeDetails: 'Exchange details',
    TripAuditInfo_Traveler: 'Traveler',
    TripAuditInfo_PrimaryEmailAddress: 'Primary email address',
    TripAuditInfo_Middle: 'Middle',
    TripAuditInfo_TaxesTotal: 'Taxes total',
    TripAuditInfo_Base: 'Base',
    TripAuditInfo_Conjunctive: 'Conjunctive',
    TripAuditInfo_Refundable: 'Refundable',
    TripAuditInfo_NotConjunctive: 'Not conjunctive',
    TripAuditInfo_NonRefundable: 'Non refundable',
    TripAuditInfo_Cancelled: 'Cancelled',
    TripAuditInfo_FormOfPayment: 'Form of payment',
    TripAuditInfo_FareCalculation: 'Fare calculation',
    TripAuditInfo_TicketStatus: 'Ticket status',
    TripAuditInfo_TripError: 'Unable to find trip data in database',
    TripAuditInfo_BackToSearchResults: 'Back to search results',
    TicketCard_AirportControl: 'Airport control',
    TicketCard_CheckedIn: 'Checked in',
    TicketCard_OriginalTicketNumber: 'Original ticket number',
    TicketCard_Amount: 'Amount',
    TicketCard_IssueDate: 'Issue date',
    TicketCard_ExchangedReissued: 'Exchanged / reissued',
    TicketCard_FlownUsed: 'Flown/used',
    TicketCard_ConvertedToFim: 'converted to fim',
    TicketCard_IrregularOperations: 'irregular operations',
    TicketCard_LiftedBoarded: 'Lifted/boarded',
    TicketCard_CouponNotification: 'Coupon notification',
    TicketCard_OpenForUse: 'Open for use',
    TicketCard_Printed: 'Printed',
    TicketCard_Refunded: 'Refunded',
    TicketCard_Suspended: 'Suspended',
    TicketCard_PaperTicket: 'Paper ticket',
    TicketCard_Unavailable: 'Unavailable',
    TicketCard_Void: 'Void',
    TicketCard_PrintExchange: 'Print exchange',
    TicketCard_Closed: 'Closed',
    TicketCard_Unknown: 'None',
    TicketCard_Voided: 'Voided',
    TicketCard_OpenAvailable: 'Open/Available',
    TicketCard_Exchanged: 'Exchanged',
    TicketCard_Lost: 'Lost',
    TicketCard_UsedFlown: 'Used/Flown',
    TicketCard_Expired: 'Expired',
    TicketCard_Voucher: 'Voucher',
    TicketCard_UnusedAvailable: 'Unused/Available',
    TicketCard_Restricted: 'Restricted',
    TicketCard_Exception: 'Exception',
    TicketCard_ServiceFee: 'Service Fee',
    TicketCard_Migrated: 'Migrated',
    TicketCard_Inactive: 'Inactive',
    TicketCard_BtaUatp: 'BTA/UATP',
    TicketCard_NotApplicable: 'Not Applicable',
    Unknown: 'Unknown',
    ExportJobs_Title: 'Scheduled reports',
    ExportJobs_Table_JobName: 'Schedule',
    ExportJobs_Table_Enable: 'Status',
    ExportJobs_Table_ReportName: 'Report name',
    ExportJobs_Table_StartDate: 'Start date',
    ExportJobs_Table_EndDate: 'End date',
    ExportJobs_Table_LastUpdatedDate: 'Last updated date',
    ExportJobs_Table_LastUpdatedBy: 'Last updated by',
    ExportJobs_Table_DeliveryMethod: 'Delivery method',
    ExportJobs_Delete_message_SUCCESS: 'Delete was successful.',
    ExportJobs_Enable_message_SUCCESS: 'Enable was successful.',
    ExportJobs_Disable_message_SUCCESS: 'Disable was successful.',
    ExportJobs_Trigger_message_SUCCESS: 'Run was successful.',
    ExportJobs_JobHistory_Title: 'Job history',
    ExportJobs_Trigger_Control: 'Run now',
    View_ExportJob_Title: 'Scheduled report',
    View_ExportJob_ReportType: 'Report type:',
    View_ExportJob_Provider: 'Chose provider:',
    View_ExportJob_Reports: 'Chose report:',
    Create_ExportJob_Title: 'Create scheduled report',
    Create_ExportJob_Control: 'Create schedule',
    Edit_ExportJob_Title: 'Edit scheduled report',
    Edit_ExportJobs_GoBack: 'Back to scheduled reports',
    Edit_ExportJob_JobName: 'Schedule name:',
    Edit_ExportJob_Reports: 'Choose the report:',
    Edit_ExportJob_ReportType: 'And the report type:',
    Edit_ExportJob_Period: 'Period for data selection:',
    Edit_ExportJob_Period_Description:
      'Choose a period that should be covered by the default report filter: last day, week, month, or custom range from 1 to 365 days.',
    Edit_ExportJob_CustomPeriod: 'Custom period for data selection (days):',
    Edit_ExportJob_CustomPeriod_Description:
      'Type the number of days covered by report. Allowed value is between 1 and 365.',
    Edit_ExportJob_Cron: 'Reoccurrence (GMT timezone):',
    Edit_ExportJob_Cron_Description: 'Set the schedule when report should be delivered.',
    Edit_ExportJob_Cron_Validate_Message: 'Please, choose report delivery time.',
    Edit_ExportJob_ActivationPeriod: 'Activation period:',
    Edit_ExportJob_DataAuthRoles: 'Data authorization role:',
    Edit_ExportJob_DataAuthRoles_ActivationPeriod_Description:
      'Choose the start and end date of the schedule and data authorization role.',
    Edit_ExportJob_ActivationPeriod_Description: 'Choose the start and end date of the schedule.',
    Edit_ExportJob_DataAuthRoles_Description: 'Choose the data authorization role.',
    Edit_ExportJob_DeliveryMethod_Wrapper: 'Delivery method:',
    Edit_ExportJob_DeliveryMethod_FTP: 'FTP method',
    Edit_ExportJob_DeliveryMethod_EMAIL: 'Email method',
    Edit_ExportJob_DeliveryMethod_Description:
      'Choose the report delivery method. FTP method is used for automated upload to OBT. Email method is used for reports to be delivered via email.',
    Edit_ExportJob_Provider: 'Choose provider:',
    Edit_ExportJob_UsersEmails: 'Recipient email address(es):',
    JobHistory_ExportJob_ActiveDates: 'Activation period',
    JobHistory_ExportJob_PeriodOfData: 'Period for data selection (days)',
    JobHistory_ExportJob_PeriodOfData_Custom: 'Custom period of data selection:',
    JobHistory_ExportJob_Created: 'Created',
    JobHistory_ExportJob_Updated: 'Updated',
    JobHistory_ExportJob_DeliveryMethod: 'Delivery method',
    JobHistory_ExportJob_Provider: 'Provider',
    JobHistory_ExportJob_Emails: 'Users emails',
    JobHistory_ExportJob_Format: 'Format',
    JobHistory_ExportJob_Report: 'Report',
    JobHistory_ExportJob_Schedule: 'Schedule',
    JobHistory_ExportJob_DetailsMessage_Control: 'More',
    JobHistory_ExportJob_HideDetailsMessage_Control: 'Less',
    JobHistory_ExportJob_ResultMessage: 'Result message:',
    JobHistory_ExportJob_ListTitle: 'List of last 10 job runs:',
    JobHistory_Job_Title: 'Job info:',
    CreateJob_Modal_Title: 'Create schedule',
    EditJob_Modal_Title: 'Edit schedule',
    Create_Job_message_SUCCESS: 'Schedule has been created successfully.',
    Retry_CreateJob_Control: 'Retry create job',
    EditJob_Form_Control: 'Save job',
    Update_Job_message_SUCCESS: 'Schedule has been updated successfully.',
    Retry_EditeJob_Control: 'Retry save job',
    Empty_GetJob_Response: 'Scheduled report not found',
    UserEmails_InvalidEmail_Message: 'Found invalid or cloned emails, they have been deleted!',
    Additional_Route_Control_ExportsJobs: 'All scheduled reports',
    PastDay_Title: 'Past day',
    PastWeek_Title: 'Past week',
    PastMonth_Title: 'Past month',
    PastQuater_Title: 'Past quarter',
    PastSixMonths_Title: 'Past 6 months',
    CustomDays_Title: 'Custom',
    Save_Report_Configuration_Successfully_Message: '✓ {0} saved successfully',
    Save_Report_Configuration_Already_Exists_Message: '! {0} already exists',
    Save_Report_Modal_Title: 'Save report',
    Invalid_Value_Message: 'The value is invalid',
    TicketNumbers_Limit_Message:
      '\nThe number of tickets that can be enriched on demand cannot be more than ',
    TicketNumbers_Limit_Error_Message: 'The maximum number of tickets exceeds the limit ',
    RecordLocators_Limit_Message:
      '\nThe number of record locators that can be enriched on demand cannot be more than ',
    RecordLocators_Limit_Error_Message: 'The maximum number of record locators exceeds the limit ',
    No_Results_Found: 'No results found',
    No_Items_To_Show: 'No items to show',
    Dynamic_Report_Sidebar_Title: 'Saved settings',
    Delete_Report_Configuration_Successfully_Message: '✓ {0} deleted successfully',
    Delete_Report_Configuration_Configuration_Message:
      'Are you sure you want to delete the {0} saved settings?',
    Rename: 'Rename',
    User_Already_Registered_Error_Message: 'User with Email {0} already registered',
  },
};
