import React, { useState } from 'react';

import {
  defaultTriggerExportJobContextValue,
  triggerExportJobContext,
  TriggerExportJobContextProps,
} from '../store/triggerExportJobContext';
import { API_REQUEST_CODE, API_REQUEST_STATUS, RequestStatus } from '../../utils/APIHandlers';
import { ExportJobsService } from '../../services/ExportJobsService';
import { useModal, useModalReturn } from '../hooks/modalHook';
import { useNotification, useNotificationReturn } from '../hooks/notificationHook';
import { ErrorFetchResponse, RestApiResponse } from '../../api/rest/models/RestApiResponse';
import { TriggerExportJobResponse } from '../../api/rest/models/ExportJob';
import { getFetchErrorMessage, getNotAcceptedErrorResponse } from '../../utils/errorHandler';
import { ExportJobDTO } from '../../api/rest/models/ExportJob/ExportJobDTO';

export const isResponseSuccess = (response: RestApiResponse<TriggerExportJobResponse>): boolean =>
  response.code === API_REQUEST_CODE.SUCCESS && response.result.statusCode === API_REQUEST_CODE.ACCEPTED;

export const isReadyForRequest = (
  requestStatus: RequestStatus,
  triggerExportJobData: ExportJobDTO | null
): boolean => requestStatus !== API_REQUEST_STATUS.REQUESTED && triggerExportJobData !== null;

const WithTriggerExportJob: React.FC = ({ children }) => {
  const [
    { isOpen, requestStatus },
    { handleOpen, handleClose, onSuccessAction, onFailAction, setRequestedState },
  ]: useModalReturn = useModal();
  const [
    { notificationStatus },
    { setFailNotificationStatus, setNotRequestedNotificationStatus, setSuccessNotificationStatus },
  ]: useNotificationReturn = useNotification();
  const [triggerExportJobData, setTriggerExportJob] = useState<ExportJobDTO | null>(null);

  const triggerExportJob = async (): Promise<void> => {
    if (!isReadyForRequest(requestStatus, triggerExportJobData)) {
      return new Promise(() => void 0);
    }

    try {
      const exportJobsService: ExportJobsService = new ExportJobsService();
      setRequestedState();

      const response =
        triggerExportJobData && (await exportJobsService.triggerExportJob(triggerExportJobData.id));
      const successResponse = response as RestApiResponse<TriggerExportJobResponse>;

      if (isResponseSuccess(successResponse)) {
        onSuccessAction();
        setSuccessNotificationStatus();
      } else {
        throw getNotAcceptedErrorResponse();
      }
    } catch (e) {
      console.error(getFetchErrorMessage(e as ErrorFetchResponse));
      onFailAction();
      setFailNotificationStatus();
    }
  };

  const getTriggerExportJobContext = (): TriggerExportJobContextProps => {
    return {
      ...defaultTriggerExportJobContextValue,
      isOpenTriggerExportJobCondition: isOpen,
      triggerExportJobData,
      triggerExportJob,
      requestTriggerExportJobStatus: requestStatus,
      notificationTriggerExportJob: notificationStatus,
      handleOpenTriggerExportJob: (data: ExportJobDTO): void => {
        setNotRequestedNotificationStatus();
        setTriggerExportJob(data);
        handleOpen();
      },
      handleCloseTriggerExportJob: (): void => {
        setTriggerExportJob(null);
        handleClose();
      },
    };
  };

  return (
    <triggerExportJobContext.Provider value={getTriggerExportJobContext()}>
      {children}
    </triggerExportJobContext.Provider>
  );
};

export { WithTriggerExportJob };
