import { APIFetch } from '../utils/APIHandlers';
import { ErrorFetchResponse, RestApiResponse } from '../api/rest/models/RestApiResponse';
import { PetApi } from '../constants/apiParams';
import {
  UpdateReportConfigurationDtoModel,
  CreateReportConfigurationDtoModel,
  ReportConfigurationDtoModel,
} from '../features/Report/Dynamic/types';
import { handleError } from '../utils/errorHandler';

export class ReportSettingsService {
  private readonly endpoint: string;

  constructor() {
    this.endpoint = `/v1/tickets/pet/reports`;
  }

  public async get(
    reportId: number
  ): Promise<RestApiResponse<ReportConfigurationDtoModel[]> | ErrorFetchResponse> {
    try {
      return await APIFetch<RestApiResponse<ReportConfigurationDtoModel[]>>(
        'get',
        PetApi,
        `${this.endpoint}/${reportId}/configurations`,
        {}
      );
    } catch (error: any) {
      return handleError(error);
    }
  }

  public async create(
    creationModel: CreateReportConfigurationDtoModel
  ): Promise<RestApiResponse<ReportConfigurationDtoModel> | ErrorFetchResponse> {
    try {
      return await APIFetch<RestApiResponse<ReportConfigurationDtoModel>>(
        'post',
        PetApi,
        `${this.endpoint}/configurations`,
        {
          body: creationModel,
        }
      );
    } catch (error: any) {
      return handleError(error);
    }
  }

  public async update(
    updateModel: UpdateReportConfigurationDtoModel
  ): Promise<RestApiResponse<ReportConfigurationDtoModel> | ErrorFetchResponse> {
    try {
      return await APIFetch<RestApiResponse<ReportConfigurationDtoModel>>(
        'put',
        PetApi,
        `${this.endpoint}/configurations`,
        {
          body: updateModel,
        }
      );
    } catch (error: any) {
      return handleError(error);
    }
  }

  public async delete(
    id: string
  ): Promise<RestApiResponse<ReportConfigurationDtoModel> | ErrorFetchResponse> {
    try {
      return await APIFetch<RestApiResponse<ReportConfigurationDtoModel>>(
        'del',
        PetApi,
        `${this.endpoint}/configurations/${id}`,
        {}
      );
    } catch (error: any) {
      return handleError(error);
    }
  }
}
