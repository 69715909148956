import { v4 as uuidv4 } from 'uuid';

import { USER_EMAILS_LIST_SEPARATOR, defaultValueEditExportJobFields } from './EditExportJob/constants';
import { ExportJobFormViewModel } from '../../models/ExportJobsAggregate/ExportJobForm';
import { getEmailPattern } from '../PetForm/helper';
import { getDateFromUTCDate, getUTCDateFromDate, setEndTime, setStartTime } from '../../utils/formatDate';
import { PeriodType } from '../../models/ExportJobsAggregate/ExportJobPeriodRange';
import { buildNextMultiDropdownValue } from './EditExportJob/Fields/helper';
import { FormatExportJob } from '../../models/ExportJobsAggregate/ExportJobFormat';
import { ExportJobDTO } from '../../api/rest/models/ExportJob/ExportJobDTO';
import { RawExportJobDTO } from '../../api/rest/models/ExportJob/Response/RawExportJobDTO';
import { CreateEditExportJobDTO } from '../../api/rest/models/ExportJob/Request/CreateEditExportJobDTO';
import { UpdateEditExportJobDTO } from '../../api/rest/models/ExportJob/Request/UpdateEditExportJobDTO';
import { ExportJobsListDTO } from '../../api/rest/models/ExportJob/ExportJobCollections';
import { ExportJobHistoryDTO } from '../../api/rest/models/ExportJob/ExportJobHistoryDTO';
import { RawExportJobHistoryDTO } from '../../api/rest/models/ExportJob/Response/RawExportJobHistoryDTO';
import { ExportJobStatus } from '../../models/ExportJobsAggregate/ExportJobHistory';

const emailPattern = getEmailPattern();

export const mapCreateEditExportJobDTOFromExportJobFormFields = (
  data: ExportJobFormViewModel
): ExportJobDTO => ({
  deliveryMethod: data.deliveryMethod,
  enabled: data.enabled,
  format: data.format,
  provider: data?.provider || '',
  cronExpression: data.cronExpression,
  jobName: data.jobName,
  userEmails: data.userEmails.map((item) => String(item.value)),
  activeStartDate: getDateFromUTCDate(
    setStartTime((data?.activeDateRange && data.activeDateRange[0]) || new Date())
  ),
  activeEndDate: getDateFromUTCDate(
    setEndTime((data?.activeDateRange && data.activeDateRange[1]) || new Date())
  ),
  dataAuthorizationRole: {
    id: data?.dataAuthorizationRoleId ?? '',
    name: data.dataAuthorizationRoleName,
  },
  reportDetails: {
    period: {
      type: data.periodType,
      offsetDays: data.periodType === PeriodType.CUSTOM ? Number(data.periodOffsetDays) : undefined,
    },
    report: {
      id: data?.report ?? 0,
      name: data.reportName,
    },
  },
  createdBy: '',
  createdDate: new Date(),
  updatedBy: '',
  updatedDate: null,
  id: '',
});

export const mapToCreateExportJobDTOFromExportJobFormViewModel = (
  data: ExportJobFormViewModel,
  email: string
): ExportJobDTO => ({
  ...mapCreateEditExportJobDTOFromExportJobFormFields(data),
  id: String(uuidv4()),
  createdDate: new Date(),
  createdBy: email,
  updatedBy: null,
  updatedDate: null,
});

export const mapToEditExportJobDTOFromExportJobFormViewModel = (
  data: ExportJobFormViewModel,
  exportJobData: ExportJobDTO,
  email: string
): ExportJobDTO => ({
  ...mapCreateEditExportJobDTOFromExportJobFormFields(data),
  id: exportJobData.id,
  createdBy: exportJobData.createdBy,
  createdDate: exportJobData.createdDate,
  updatedBy: email,
  updatedDate: new Date(),
});

export const wrapExportJobDTOWithUpdateDateFromExportJobDTO = (
  exportJobData: ExportJobDTO,
  email: string
): ExportJobDTO => ({
  ...exportJobData,
  updatedBy: email,
  updatedDate: new Date(),
});

export const mapToCreateExportJobDTOFromExportJobDTO = (data: ExportJobDTO): CreateEditExportJobDTO => ({
  activeStartDate: data.activeStartDate.toISOString(),
  activeEndDate: data.activeEndDate.toISOString(),
  dataAuthorizationRoleId: data.dataAuthorizationRole?.id ?? '',
  reportDetails: data.reportDetails
    ? {
        period: data.reportDetails.period,
        reportId: data.reportDetails.report.id ?? 0,
      }
    : null,
  deliveryMethod: data.deliveryMethod,
  enabled: data.enabled,
  format: mapFormatToString(data.format),
  jobName: data.jobName,
  provider: data.provider,
  cronExpression: data.cronExpression,
  userEmails: data.userEmails,
});

export const mapToEditExportJobDTOFromExportJobDTO = (data: ExportJobDTO): UpdateEditExportJobDTO => ({
  ...mapToCreateExportJobDTOFromExportJobDTO(data),
  id: data.id,
});

export const mapToExportJobFieldsFromExportJobDTO = (data: ExportJobDTO): ExportJobFormViewModel => ({
  cronExpression: data.cronExpression,
  dataAuthorizationRoleId: data.dataAuthorizationRole?.id ?? null,
  dataAuthorizationRoleName: data.dataAuthorizationRole?.name ?? '',
  deliveryMethod: data.deliveryMethod,
  format: data.format,
  jobName: data.jobName,
  provider: data.provider,
  enabled: data.enabled,
  periodType: data.reportDetails?.period.type ?? PeriodType.DAY,
  periodOffsetDays: data.reportDetails?.period.offsetDays ?? defaultValueEditExportJobFields.periodOffsetDays,
  report: data.reportDetails?.report.id ?? null,
  reportName: data.reportDetails?.report.name ?? '',
  userEmails: buildNextMultiDropdownValue(
    USER_EMAILS_LIST_SEPARATOR,
    emailPattern.pattern.value,
    data.userEmails
  ).result,
  activeDateRange: [getUTCDateFromDate(data.activeStartDate), getUTCDateFromDate(data.activeEndDate)],
});

export const sortExportJobsByCreatedUpdatedDatesDESC = (data: ExportJobsListDTO): void => {
  data.sort((a: ExportJobDTO, b: ExportJobDTO) => {
    const ADate = (a.updatedDate && new Date(a.updatedDate)) || new Date(a.createdDate);
    const BDate = (b.updatedDate && new Date(b.updatedDate)) || new Date(b.createdDate);

    return BDate.getTime() - ADate.getTime();
  });
};

export const mapExportJobDTOFromRawExportJobDTO = (data: RawExportJobDTO): ExportJobDTO => ({
  ...data,
  activeStartDate: new Date(data.activeStartDate),
  activeEndDate: new Date(data.activeEndDate),
  createdDate: new Date(data.createdDate),
  updatedDate: (data?.updatedDate && new Date(data.updatedDate)) || null,
  format: mapStringToFormat(data.format),
});

const mapExportJobStatus = (status: string): ExportJobStatus => {
  switch (status) {
    case 'Failed':
      return ExportJobStatus.Failed;
    case 'NotStarted':
      return ExportJobStatus.NotStarted;
    case 'Running':
      return ExportJobStatus.Running;
    case 'Succeeded':
      return ExportJobStatus.Succeeded;
    case 'Undefined':
      return ExportJobStatus.Undefined;
    default:
      return ExportJobStatus.Undefined;
  }
};

export const mapExportJobHistoryDTOFromRawExportJobHistoryDTO = (
  data: RawExportJobHistoryDTO
): ExportJobHistoryDTO => ({
  ...data,
  status: mapExportJobStatus(data.status),
});

const mapStringToFormat = (format: string): FormatExportJob => {
  switch (format) {
    case 'CSV':
      return FormatExportJob.CSV;
    case 'XLSX':
      return FormatExportJob.XLSX;
    default:
      return FormatExportJob.CSV;
  }
};

const mapFormatToString = (format: FormatExportJob): string => {
  switch (format) {
    case FormatExportJob.CSV:
      return 'CSV';
    case FormatExportJob.XLSX:
      return 'XLSX';
    default:
      return 'CSV';
  }
};
