import {
  CreateFeatureAccessGroupDTO,
  FeatureAccessGroupApiDTO,
  FeatureAccessGroupsRequestDTO,
  UpdateFeatureAccessGroupDTO,
} from '../api/rest/models/FeatureAccessGroups';
import { PaginatedResponse } from '../api/graphql/models/common';
import { RestApiResponse } from '../api/rest/models/RestApiResponse';
import { PetApi } from '../constants/apiParams';
import { APIFetch } from '../utils/APIHandlers';

export class FeatureAccessGroupService {
  private readonly endpoint: string;

  constructor() {
    this.endpoint = '/v1/tickets/pet/featureAccessGroups';
  }

  public async getAll({
    limit,
    offset,
  }: FeatureAccessGroupsRequestDTO): Promise<RestApiResponse<PaginatedResponse<FeatureAccessGroupApiDTO>>> {
    return await APIFetch<RestApiResponse<PaginatedResponse<FeatureAccessGroupApiDTO>>>(
      'get',
      PetApi,
      `${this.endpoint}/all?limit=${limit}&offset=${offset}`,
      {}
    );
  }

  public async getById(id: string): Promise<RestApiResponse<FeatureAccessGroupApiDTO>> {
    return await APIFetch<RestApiResponse<FeatureAccessGroupApiDTO>>(
      'get',
      PetApi,
      `${this.endpoint}/${id}`,
      {}
    );
  }

  public async insert(
    group: CreateFeatureAccessGroupDTO
  ): Promise<RestApiResponse<FeatureAccessGroupApiDTO>> {
    return await APIFetch<RestApiResponse<FeatureAccessGroupApiDTO>>('post', PetApi, `${this.endpoint}`, {
      body: group,
    });
  }

  public async update(
    group: UpdateFeatureAccessGroupDTO
  ): Promise<RestApiResponse<FeatureAccessGroupApiDTO>> {
    return await APIFetch<RestApiResponse<FeatureAccessGroupApiDTO>>('put', PetApi, `${this.endpoint}`, {
      body: group,
    });
  }
}
