import React, { useState } from 'react';

import {
  defaultExportJobHistoryContextValue,
  exportJobHistoryContext,
  ExportJobHistoryContextProps,
} from '../store/exportJobHistoryContext';
import { ExportJobsService } from '../../services/ExportJobsService';
import { useModal, useModalReturn } from '../hooks/modalHook';
import { API_REQUEST_STATUS } from '../../utils/APIHandlers';
import { ExportJobHistoryListDTO } from '../../api/rest/models/ExportJob/ExportJobCollections';
import { mapExportJobHistoryDTOFromRawExportJobHistoryDTO } from '../../features/ExportJobsContainer/exportJobsMapper';

const WithExportJobHistory: React.FC = ({ children }) => {
  const [
    { isOpen, requestStatus },
    { handleOpen, handleClose, setRequestedState, setSuccessState, setFailState },
  ]: useModalReturn = useModal();
  const [exportJobHistoryData, setExportJobHistoryData] = useState<ExportJobHistoryListDTO>([]);

  const getExportJobHistory = async (id: string) => {
    if (requestStatus !== API_REQUEST_STATUS.REQUESTED) {
      try {
        const exportJobsService: ExportJobsService = new ExportJobsService();
        setRequestedState();

        const response = await exportJobsService.getJobHistory(id);
        const data = response.result.map(mapExportJobHistoryDTOFromRawExportJobHistoryDTO);

        setSuccessState();
        setExportJobHistoryData(data);
      } catch (error) {
        console.error(error);
        setFailState();
      }
    }
  };

  const getExportJobHistoryContext = (): ExportJobHistoryContextProps => {
    return {
      ...defaultExportJobHistoryContextValue,
      isOpenJobHistory: isOpen,
      exportJobHistoryData,
      getExportJobHistory,
      requestExportJobHistoryStatus: requestStatus,
      handleOpenExportJobHistory: handleOpen,
      handleCloseExportJobHistory: (): void => {
        setExportJobHistoryData([]);
        handleClose();
      },
    };
  };

  return (
    <exportJobHistoryContext.Provider value={getExportJobHistoryContext()}>
      {children}
    </exportJobHistoryContext.Provider>
  );
};

export { WithExportJobHistory };
