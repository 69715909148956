import React from 'react';
import { RequestStatus, API_REQUEST_STATUS } from '../../utils/APIHandlers';
import { ExportJobDTO } from '../../api/rest/models/ExportJob/ExportJobDTO';

export interface TriggerExportJobContextProps {
  isOpenTriggerExportJobCondition: boolean;
  triggerExportJobData: ExportJobDTO | null;
  triggerExportJob: () => Promise<void>;
  requestTriggerExportJobStatus: RequestStatus;
  notificationTriggerExportJob: RequestStatus;
  handleOpenTriggerExportJob: (ExportJobId: ExportJobDTO) => void;
  handleCloseTriggerExportJob: () => void;
}

export const defaultTriggerExportJobContextValue: TriggerExportJobContextProps = {
  isOpenTriggerExportJobCondition: false,
  triggerExportJobData: null,
  triggerExportJob: async () => new Promise(() => void 0),
  requestTriggerExportJobStatus: API_REQUEST_STATUS.NOT_REQUESTED,
  notificationTriggerExportJob: API_REQUEST_STATUS.NOT_REQUESTED,
  handleOpenTriggerExportJob: () => void 0,
  handleCloseTriggerExportJob: () => void 0,
};

const triggerExportJobContext = React.createContext<TriggerExportJobContextProps>(
  defaultTriggerExportJobContextValue
);

export { triggerExportJobContext };
