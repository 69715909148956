import React, { SyntheticEvent, useEffect, useContext, ReactElement, useCallback } from 'react';
import { UseFormGetValues } from 'react-hook-form/dist/types/form';
import { FieldValues } from 'react-hook-form/dist/types/fields';
import { FieldErrors, FieldError } from 'react-hook-form/dist/types/errors';

import { dataAuthorizationRolesContext } from '../../../../shared/store/dataAuthorizationRolesContext';
import SelectWithInfo from '../../../PetForm/SelectWithInfo';
import { getTranslation } from '../../../../utils/getTranslation';
import { DropdownValueType } from '../../../PetForm/types';
import { DropdownViewModel } from '../../../../models/DropdownViewModel';
import { API_REQUEST_STATUS } from '../../../../utils/APIHandlers';
import { DataAuthorizationRole } from '../../../../models/DataAuthorizationRolesAggregate/DataAuthorizationRole';
import { getTextFromValue } from './helper';
import ResponseErrorMessage from '../../../../shared/controls/ResponseErrorMessage';
import { ExportJobFormViewModel } from '../../../../models/ExportJobsAggregate/ExportJobForm';

interface DataAuthorizationRolesFieldProps {
  onChange: (value: string, text: string) => void;
  errors: FieldErrors<FieldValues>;
  getValues: UseFormGetValues<ExportJobFormViewModel>;
  dataTestId?: string;
  required?: boolean;
}

const DataAuthorizationRolesField: React.FC<DataAuthorizationRolesFieldProps> = (props): ReactElement => {
  const { onChange, errors, getValues, dataTestId, required } = props;
  const { getDataAuthorizationRoles, dataAuthorizationRolesData, requestDataAuthorizationRolesStatus } =
    useContext(dataAuthorizationRolesContext);

  const handleGetDataAuthorizationRoles = useCallback((): void => {
    void getDataAuthorizationRoles();
  }, [getDataAuthorizationRoles]);

  useEffect(() => {
    if (requestDataAuthorizationRolesStatus !== API_REQUEST_STATUS.REQUESTED) {
      handleGetDataAuthorizationRoles();
    }
  }, []);

  return (
    <>
      <SelectWithInfo
        defaultValue={getValues('dataAuthorizationRoleId') as string}
        options={dataAuthorizationRolesData.map(
          (item: DataAuthorizationRole, index: number): DropdownViewModel => ({
            key: String(index),
            value: item.id,
            text: item.roleName,
          })
        )}
        label={getTranslation('Edit_ExportJob_DataAuthRoles')}
        error={errors?.dataAuthorizationRoleId as FieldError}
        onChange={(_e: SyntheticEvent, value: DropdownValueType) => {
          onChange(String(value.value), getTextFromValue(value));
        }}
        required={required}
        isLoaded={requestDataAuthorizationRolesStatus === API_REQUEST_STATUS.REQUESTED}
        dataTestId={dataTestId}
      />

      {requestDataAuthorizationRolesStatus === API_REQUEST_STATUS.REQUEST_FAILURE && (
        <ResponseErrorMessage
          onRetryClick={handleGetDataAuthorizationRoles}
          dataTestId="dataAuthorizationRoleIdField-messageError"
        />
      )}
    </>
  );
};

export default DataAuthorizationRolesField;
