import React from 'react';
import { RequestStatus, API_REQUEST_STATUS } from '../../utils/APIHandlers';
import { ExportJobHistoryListDTO } from '../../api/rest/models/ExportJob/ExportJobCollections';

export interface ExportJobHistoryContextProps {
  isOpenJobHistory: boolean;
  exportJobHistoryData: ExportJobHistoryListDTO;
  getExportJobHistory: (id: string) => Promise<void>;
  requestExportJobHistoryStatus: RequestStatus;
  handleOpenExportJobHistory: () => void;
  handleCloseExportJobHistory: () => void;
}

export const defaultExportJobHistoryContextValue: ExportJobHistoryContextProps = {
  isOpenJobHistory: false,
  exportJobHistoryData: [],
  getExportJobHistory: async () => new Promise(() => void 0),
  requestExportJobHistoryStatus: API_REQUEST_STATUS.NOT_REQUESTED,
  handleOpenExportJobHistory: () => void 0,
  handleCloseExportJobHistory: () => void 0,
};

const exportJobHistoryContext = React.createContext<ExportJobHistoryContextProps>(
  defaultExportJobHistoryContextValue
);

export { exportJobHistoryContext };
