import React, { useContext } from 'react';
import { Table } from 'semantic-ui-react';

import TableLabel from '../../TableLabel';
import { getTranslation } from '../../../utils/getTranslation';
import { DeliveryMethodValue } from '../../../models/ExportJobsAggregate/ExportJobDelivery';
import { getFormattedUTCString } from '../../../utils/formatDate';
import { exportJobsContext } from '../../../shared/store/exportJobsContext';
import ExportJobsDropdown from './ExportJobsDropdown';
import './styles.scss';
import { ExportJobDTO } from '../../../api/rest/models/ExportJob/ExportJobDTO';

const ExportJobsTable: React.FC = () => {
  const { exportJobsData } = useContext(exportJobsContext);

  return (
    <div className="container-exportJobsTable" data-testid="tableExportJobs">
      <Table celled className="static">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{getTranslation('ExportJobs_Table_JobName')}</Table.HeaderCell>
            <Table.HeaderCell>{getTranslation('ExportJobs_Table_Enable')}</Table.HeaderCell>
            <Table.HeaderCell>{getTranslation('DataAuthorizationRole')}</Table.HeaderCell>
            <Table.HeaderCell>{getTranslation('ExportJobs_Table_ReportName')}</Table.HeaderCell>
            <Table.HeaderCell>{getTranslation('ExportJobs_Table_DeliveryMethod')}</Table.HeaderCell>
            <Table.HeaderCell>{getTranslation('ExportJobs_Table_StartDate')}</Table.HeaderCell>
            <Table.HeaderCell>{getTranslation('ExportJobs_Table_EndDate')}</Table.HeaderCell>
            <Table.HeaderCell>{getTranslation('ExportJobs_Table_LastUpdatedDate')}</Table.HeaderCell>
            <Table.HeaderCell>{getTranslation('ExportJobs_Table_LastUpdatedBy')}</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body data-testid="tableBodyExportJobs">
          {exportJobsData.map((exportJob: ExportJobDTO) => (
            <Table.Row key={exportJob.id} className="rowTableExportJobs">
              <Table.Cell data-testid="rowJobNameExportJob">{exportJob.jobName}</Table.Cell>
              <Table.Cell data-testid="rowEnableExportJob">
                {exportJob.enabled ? (
                  <TableLabel text={getTranslation('Enabled')} active={true} />
                ) : (
                  <TableLabel text={getTranslation('Disabled')} active={false} />
                )}
              </Table.Cell>
              <Table.Cell data-testid="rowDataAuthorizationRoleIdExportJob">
                {exportJob?.dataAuthorizationRole?.name}
              </Table.Cell>
              <Table.Cell data-testid="rowAuthDataExportJob">
                {exportJob?.reportDetails?.report?.name}
              </Table.Cell>
              <Table.Cell data-testid="rowDeliveryMethodExportJob">
                {DeliveryMethodValue[exportJob.deliveryMethod]}
              </Table.Cell>
              <Table.Cell data-testid="rowActiveStartDateExportJob">
                {getFormattedUTCString(exportJob.activeStartDate)}
              </Table.Cell>
              <Table.Cell data-testid="rowActiveEndDateExportJob">
                {getFormattedUTCString(exportJob.activeEndDate)}
              </Table.Cell>
              <Table.Cell data-testid="rowUpdatedDateExportJob">
                {getFormattedUTCString(
                  (exportJob?.updatedDate && exportJob.updatedDate) || exportJob.createdDate
                )}
              </Table.Cell>
              <Table.Cell data-testid="rowUpdatedByExportJob">
                {exportJob?.updatedBy || exportJob?.createdBy}
              </Table.Cell>
              <Table.Cell>
                <ExportJobsDropdown exportJob={exportJob} />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  );
};

export default ExportJobsTable;
